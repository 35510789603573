import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { KumrongService } from 'src/app/services/kumrong.service';
import { formatNumber } from '@angular/common';
import { ProvincialHarvestYieldReport } from 'src/app/models/provincial-harvest-yield-report';
import { Province } from 'src/app/models/address';
import { ProvinceService } from 'src/app/services/province.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-provincial-yield',
  templateUrl: './provincial-yield.component.html',
  styleUrls: ['./provincial-yield.component.scss']
})
export class ProvincialYieldComponent implements OnInit {
  form: FormGroup;

  displayedColumns = [
    'position',
    'crop_name',
    'total_estimation_yield',
    'total_actual_yield'
  ];

  provincialYields: ProvincialHarvestYieldReport[] = [];
  dataSource = [];
  columnGroups: string[] = [];
  isLoading: boolean = false;

  provinces: Province[] = [];

  params = {
    start_date: null,
    end_date: null,
    province: 0
  }

  trans: string[] = [];
  // https://stackoverflow.com/questions/42462764/javascript-export-csv-encoding-utf-8-issue
  BOM = '\uFEFF'; // Byte Order Mark,


  constructor(
    private fb: FormBuilder,
    private kumroungService: KumrongService,
    private provinceService: ProvinceService,
    private translateService: TranslateService
  ) {
    this.form = this.fb.group({
      startDate: [''],
      endDate: ['']
    })
  }

  async ngOnInit(): Promise<void> {
    this.getTranslations();
    await this.getProvinces();
    await this._fetchDataAndUpdateTable();
  }

  async getTranslations(): Promise<void> {
    this.trans = await this.translateService
      .get([
        'No',
        'Tons',
        'Crop.Name',
        'HarvestEstimation.TotalEstimationYield',
        'HarvestEstimation.TotalActualYield',
        'HarvestEstimation.Estimation',
        'HarvestEstimation.Actual',
        'HarvestEstimation.Note'
      ])
      .toPromise();
  }

  async getProvinces() {
    this.isLoading = true;
    try {

      this.provinces = (await this.provinceService.getDropDownProvince().toPromise()).data;
      this.isLoading = false;
    } catch (err) {
      this.isLoading = false;
      console.error(err);
    }
  }

  private async _fetchDataAndUpdateTable() {
    try {
      this.isLoading = true;
      this.provincialYields = (await this.kumroungService.getProvincialHarvestYieldReport(this.params).toPromise()).data;

      this.resetData();
      this._generateColumnGroups();
      this._generateData();

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.error(error);
    }
  }

  resetData() {
    this.displayedColumns = [
      'position',
      'crop_name',
      'total_estimation_yield',
      'total_actual_yield'
    ];

    this.dataSource = [];
    this.columnGroups = [];
  }

  onSelectChange(event: any) {
    this.params.province = event?.value ?? 0;
    this._fetchDataAndUpdateTable();
  }

  onDateChange(field: string, event: any) {
    this.params[field] = event.value ? moment(event.value).locale('en').format('YYYY-MM-DD') : null;
    if (
      this.params.start_date && this.params.start_date != '' &&
      this.params.end_date && this.params.end_date != ''
    ) {
      this._fetchDataAndUpdateTable();
    }
  }

  private _generateData() {
    this.dataSource = this.provincialYields.map(any => {
      let data = {};

      data['crop_name'] = any.crop?.name_km;
      data['total_estimation_yield'] = formatNumber(any?.total_estimate_harvest_quantity, 'en', '1.0-2');
      data['total_actual_yield'] = formatNumber(any?.total_harvest_quantity, 'en', '1.0-2');

      any.data.forEach(dd => {
        data[`Estimation_${dd.province?._id}`] = formatNumber(dd?.total_estimate_harvest_quantity, 'en', '1.0-2');
        data[`Actual_${dd.province?._id}`] = formatNumber(dd?.total_harvest_quantity, 'en', '1.0-2');
      });

      return data;
    })
  }

  // Create column group(column group = column colspan) header based on
  // which data length greater than others
  private _generateColumnGroups() {

    if (this.provincialYields.length) {

      const dataIndexes = this.provincialYields.map(d => d?.data?.length);
      let maxIndex = dataIndexes.indexOf(Math.max(...dataIndexes));
      // take max one of data length to generate column header
      this.provincialYields[maxIndex].data.forEach(dt => {
        this.displayedColumns.push(`Estimation_${dt.province?._id}`)
        this.displayedColumns.push(`Actual_${dt.province?._id}`)
        this.columnGroups.push(dt.province?.name);
      });

      this.columnGroups = ['no', 'crop_n', 'total_e', 'total_a', ...this.columnGroups];
    }
  }

  exportFile() {
    const colTitle1 = [];

    this.displayedColumns.forEach((dc, i) => {
      let th = this.trans['HarvestEstimation.' + dc.split('_')[0]];

      if (i > 3) {
        colTitle1.push(th)
      } else {
        colTitle1.push('');
      }
    })

    const rows = this.dataSource.map((d, i) => {
      return this.displayedColumns.map((col, idx) => {

        if (col === 'position') {
          return i + 1;
        } else {
          // return d[col] && d[col] != '' ? d[col].replace(',', '') : 0
          return typeof d[col] === 'string' && d[col].includes(',') ? `"${d[col]}"` : `"${d[col] || '0'}"`;
        }

      })
    });

    // Check if there is data available
    if (this.dataSource.length === 0) {
      return;
    }

    rows.unshift(colTitle1); // add column title to the first row

    let colTitle2 = [
      this.trans['No'],
      this.trans['Crop.Name'],
      this.trans['HarvestEstimation.TotalEstimationYield'],
      this.trans['HarvestEstimation.TotalActualYield']
    ];
    this.columnGroups.forEach((item, i) => {
      if (i > 3) {
        colTitle2.push(item.split('_')[0]);
        colTitle2.push(item.split('_')[0]);
      }
    })

    rows.unshift(colTitle2);
    rows.push(['']); // add space in a row above
    rows.push([this.trans['HarvestEstimation.Note']]);

    let csvContent =
      'data:text/csv;charset=utf-8,' +
      this.BOM +
      rows.map((e) => e.join(',')).join('\n');

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    let reportName =
      'provincial-yield-' + moment().locale('en').format('YYYY-MM-DD') + '.csv';
    link.setAttribute('download', reportName);
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  onResetForm(startDateInput: HTMLInputElement, endDateInput: HTMLInputElement): void {
    startDateInput.value = '';
    endDateInput.value = '';
    this.params.start_date = null;
    this.params.end_date = null;
    this.form.reset();
    this.clearDateFields();
    this._fetchDataAndUpdateTable();
  }

  private clearDateFields(): void {
    this.params = {
      start_date: null,
      end_date: null,
      province: 0
    }
  }

}
