import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-photo-viewer',
  templateUrl: './photo-viewer.component.html',
  styleUrls: ['./photo-viewer.component.scss']
})
export class PhotoViewerComponent implements OnInit {

  currentIndex: number = 0;
  token: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<PhotoViewerComponent>,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.token = this.localStorageService.get(LocalStorageEnum.token);
    this.currentIndex = this.data.index;
  }

  onPrev() {
    if (this.currentIndex <= 0) {
      this.currentIndex = this.data.photos.length - 1;
    }
    else {
      this.currentIndex--;
    }
  }

  onNext() {
    if (this.currentIndex < this.data.photos.length - 1) {
      this.currentIndex++;
    }
    else {
      this.currentIndex = 0;
    }
  }
}
