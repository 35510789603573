<ng-container>
  <div class="row">
    <div class="col-md-auto pt-2">
      <img [src]="farmer?.photo_url ? getImageUrl(farmer?.photo_url) : 'assets/svgs/noun-drop-image.svg'"
        onerror="this.src='assets/svgs/noun-drop-image.svg'"
        width="140px"
        height="170px"
        class="profile-image"
      >
    </div>

    <div class="col-lg pt-2 flex-container">
      <!-- farmer info -->
      <div style="flex: 1;">
        <app-info-row
          label='{{ "FarmerPage.IdCard" | translate }}'
          value='{{ farmer?.id_card || ("N/A" | translate) }}'
        >
        </app-info-row>
        <app-info-row
          label='{{ "FarmerPage.LastName" | translate }}'
          value='{{ farmer?.last_name || ("N/A" | translate) }}'
        >
        </app-info-row>
        <app-info-row
          label='{{ "FarmerPage.FirstName" | translate }}'
          value='{{ farmer?.first_name || ("N/A" | translate) }}'
        >
        </app-info-row>
        <app-info-row
          label='{{ "FarmerPage.Gender" | translate }}'
          value='{{ farmer?.gender ? (genderEnum[farmer?.gender] | translate) : ("N/A" | translate) }}'
        >
        </app-info-row>
        <app-info-row
          label='{{ "FarmerMemberInfo.DateOfBirth" | translate }}'
          value='{{
            farmer?.dob
            ? (farmer?.dob | amDateFormat: "DD / MMM / YYYY")
            : ("N/A" | translate)
          }}'
        >
        </app-info-row>
        <app-info-row
          label='{{ "FarmerPage.Ethnicity" | translate }}'
          value='{{
            farmer?.ethnic
            ? ("Ethnic." + farmer?.ethnic | translate)
            : ("N/A" | translate)
          }}'
        >
        </app-info-row>
        <app-info-row
          label='{{ "FarmerPage.PoorCard" | translate }}'
          value='{{
            farmer?.poor_type
            ? ("Card." + farmer?.poor_type | translate)
            : ("N/A" | translate)
          }}'
        >
        </app-info-row>
        <app-info-row
          label='{{ "UsersPage.PhoneNumber" | translate }}'
          value='{{ getFarmerTels() || ("N/A" | translate) }}'
        >
        </app-info-row>
      </div>
      <!-- farmer address -->
      <div style="flex: 1; align-items: baseline; display: ruby;">
        <app-info-row
          label='{{ "FarmerPage.Provice" | translate }}'
          value='{{ farmer?.address?.province?.name ?? "N/A" | translate }}'
        >
        </app-info-row>

        <app-info-row
          label='{{ "FarmerPage.DistrictKh" | translate }}'
          value='{{ farmer?.address?.district?.name ?? "N/A" | translate }}'
        >
        </app-info-row>

        <app-info-row
          label='{{ "FarmerPage.CommuneSk" | translate }}'
          value='{{ farmer?.address?.commune?.name ?? "N/A" | translate }}'
        >
        </app-info-row>

        <app-info-row
          label='{{ "FarmerPage.Village" | translate }}'
          value='{{ farmer?.address?.village?.name ?? "N/A" | translate }}'
        >
        </app-info-row>
      </div>
      <!-- Photo -->
      <div style="flex: 1;">
        {{ "FarmerPage.IdentityPhoto" | translate }}
        <div class="wrraper-image" [ngClass]="farmer?.id_card_photo_url ? 'wrraper-has-image' : 'wrraper-no-image'">
          <img alt="Avatar image"
            [src]="farmer?.id_card_photo_url
              ? getImageUrl(farmer?.id_card_photo_url)
              : '/assets/svgs/id-image.svg'"
            onerror="this.src='/assets/svgs/id-image.svg'"
            (click)="onViewImage(farmer?.id_card_photo_url)"
          />
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="farmlands.length > 0">
    <app-household-head-farmland [farmlands]="farmlands">
    </app-household-head-farmland>

    <app-household-head-crop-cycle [farmlands]="farmlands" [farmerId]="farmerId">
    </app-household-head-crop-cycle>
  </ng-container>
</ng-container>