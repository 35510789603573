import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { GenderEnum } from 'src/app/models/enums/gender.enum';
import { TokenPipe } from 'src/app/shares/token-pipe/pipes/token.pipe';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PhotoViewerComponent } from 'src/app/shares/custom-carousel/photo-viewer/photo-viewer.component';
import { Farmland } from 'src/app/models/farmland';
import { FarmersService } from '../../services/farmers.service';

@Component({
  selector: 'app-household-head-tab',
  templateUrl: './household-head-tab.component.html',
  styleUrls: ['./household-head-tab.component.scss']
})

export class HouseholdHeadTabComponent implements OnInit {
  genderEnum = GenderEnum;
  farmlands: Array<Farmland> = [];

  @Input() farmerId: string;
  @Input() farmer: any;

  constructor(
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private service: FarmersService,
  ) {}

  ngOnInit(): void {
    this.getFarmerFarmlands();
  }

  async getFarmerFarmlands(): Promise<void> {
    this.service.getFarmerFarmlands(this.farmerId).subscribe((res) => {
      this.farmlands = res.data;
    }, (err) => {
      console.log(err);
    });
  }

  getFarmerTels(): string {
    if (!this.farmer || this.farmer?.tels.lenght == 0) {
      return "";
    }
    return this.farmer?.tels.join(' / ')
  }

  getImageUrl(imageUrl) {
    if (!imageUrl)
      return null;

    return new TokenPipe(this.localStorageService).transform(imageUrl);
  }

  onViewImage(photo_url): void {
    if (!photo_url) {
      return
    }
    this.dialog.open(PhotoViewerComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'full-screen-modal',
      data: {
        index: 0,
        photos: photo_url
          ? [photo_url]
          : ['/assets/svgs/avatar-person-image.svg'],
      },
    })
  }
}