import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Province } from '../models/address';
import { BaseDatatable } from '../models/datatables/base.datatable';
import { BaseResponse } from '../models/responses/base.response';
import { BaseCrudService } from './base-crud.service';

@Injectable({
  providedIn: 'root'
})
export class ProvinceService extends BaseCrudService<Province>{

  constructor(injector: Injector) {
    super(injector);
    this.path = environment.portal_url + '/provinces';
  }


  getDropDownProvince(): Observable<BaseResponse> {
    return this.requestService.get<BaseResponse>(
      this.path
    )
  }
}
