import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss'],
})

export class ProfileImageComponent implements OnInit {
  @Input() profileImage: string;
  @Input() fullName: string;
  @Input() caption: string;
  @Input() profileStyle: object;
  @Input() classCaption: string;
  @Input() className: string;

  constructor() {}

  ngOnInit(): void {}
}
