<form [formGroup]="form" autocomplete="off" (ngSubmit)="onSubmit()">
  <div class="dialog-header">
    <h3 class="mat-h3" mat-dialog-title>
      {{ "ProfilePage.ChangePassword" | translate }}
    </h3>
  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-content>
    <div class="form-group">
      <div class="row">
        <div class="col-sm-12">
          <mat-label>
            {{ "ProfilePage.CurrentPassword" | translate }}
            <span class="required"></span>
          </mat-label>
          <mat-form-field class="form-control" appearance="outline">
            <button
              type="button"
              mat-icon-button
              matSuffix
              (click)="hideCurrentPassword = !hideCurrentPassword"
              [attr.aria-label]="'Hide current password'"
              [attr.aria-pressed]="hideCurrentPassword"
            >
              <mat-icon class="open-eye">
                {{ hideCurrentPassword ? "visibility_off" : "visibility" }}
              </mat-icon>
            </button>
            <input
              matInput
              [type]="hideCurrentPassword ? 'password' : 'text'"
              formControlName="current_password"
            />
            <mat-error
              *ngIf="form.get('current_password')?.hasError('required')"
            >
              {{ "ValidationErr.Required" | translate }}
            </mat-error>
            <mat-error
              *ngIf="form.get('current_password')?.hasError('minlength')"
            >
              {{ "ValidationErr.PasswordLength" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <mat-label>
            {{ "ProfilePage.NewPassword" | translate }}
            <span class="required"></span>
          </mat-label>
          <mat-form-field class="form-control" appearance="outline">
            <button
              type="button"
              mat-icon-button
              matSuffix
              (click)="hideNewPassword = !hideNewPassword"
              [attr.aria-label]="'Hide new password'"
              [attr.aria-pressed]="hideNewPassword"
            >
              <mat-icon class="open-eye">
                {{hideNewPassword ? "visibility_off" : "visibility"}}
              </mat-icon>
            </button>
            <input
              matInput
              [type]="hideNewPassword ? 'password' : 'text' "
              formControlName="new_password"
            />
            <mat-error *ngIf="form.get('new_password')?.hasError('required')">
              {{ "ValidationErr.Required" | translate }}
            </mat-error>
            <mat-error *ngIf="form.get('new_password')?.hasError('minlength')">
              {{ "ValidationErr.PasswordLength" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <mat-label>
            {{ "ProfilePage.ConfirmPassword" | translate }}
            <span class="required"></span>
          </mat-label>
          <mat-form-field class="form-control" appearance="outline">
            <button
              type="button"
              mat-icon-button
              matSuffix
              (click)="hideConfirmPassword = !hideConfirmPassword"
              [attr.aria-label]="'Hide confirm password'"
              [attr.aria-pressed]="hideConfirmPassword"
            >
              <mat-icon class="open-eye">
                {{hideConfirmPassword ? "visibility_off" : "visibility"}}
              </mat-icon>
            </button>
            <input
              matInput
              [type]="hideConfirmPassword? 'password' : 'text' "
              formControlName="confirm_password"
            />
            <mat-error *ngIf="form.get('confirm_password')?.errors?.mustMatch">
              {{ "ValidationErr.ConfirmPasswordNoMatch" | translate }}
            </mat-error>
            <span></span>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button
      class="btn-md btn-cancel"
      type="button"
      mat-stroked-button
      color="primary"
      (click)="onCancel()"
      mat-dialog-close
      aria-label="Cancel"
    >
      <mat-icon svgIcon="icon-concel"></mat-icon>
      {{ "ActionBtn.Cancel" | translate }}
    </button>
    <button
      [disabled]="form.invalid"
      class="btn-md"
      type="submit"
      mat-flat-button
      color="primary"
      cdkFocusInitial
      aria-label="Submit"
    >
      <mat-icon svgIcon="icon-bookmark"></mat-icon>
      {{ "ActionBtn.Save" | translate }}
    </button>
  </div>
</form>
<div *ngIf="isLoading" class="app-loading"></div>
