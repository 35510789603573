<div class="toolbar header" role="banner">
  <div class="toolbar__left">
    <button mat-icon-button (click)="toggleShowNav.emit()">
      <mat-icon svgIcon="icon-hamburger">
      </mat-icon>
    </button>
    <div (click)="navigateTo()" class="app-title">
      {{ "AppTitle" | translate }}
    </div>
    <div class="app-version">
      ( {{ "KasVersion" | translate }} )
    </div>
  </div>
  <div class="toolbar__right">
    <div class="avtar-toggle-menu" [matMenuTriggerFor]="menu">
      <button mat-button class="profile-menu-btn" aria-label="button for wrapping profile image">
        <app-profile-image [profileImage]="profile?.user?.photo_url + '?token=' + token"
          [fullName]="profile?.user | fullName: ''" [caption]="'@' + profile?.user.username" [profileStyle]="profileStyle"
          [className]="className">
        </app-profile-image>
      </button>
    </div>
    <mat-menu class="option-menu profile" #menu="matMenu" xPosition="before">
      <h3 class="mat-h3 menu-title">{{ "Menu.Account" | translate }}</h3>
      <mat-divider></mat-divider>
      <button class="menu-profile" mat-menu-item (click)="openEditProfile()">
        <div class="m-pr-item">
          <button mat-icon-button>
            <img alt="Avatar image" mat-card-avatar class="avtar-icon" [src]="
                          profile?.user?.photo_url
                            ? profile?.user?.photo_url + '?token=' + token
                            : '/assets/svgs/avatar-person.svg'
                        " onerror="this.src='/assets/svgs/avatar-person.svg'" style="margin-right: unset" />
          </button>
          <div class="m-pr-name">
            <div class="m-pr-full-name">{{ profile?.user | fullName: '' | uppercase }}</div>
            <div class="edit-profile">
              {{ "Menu.EditUser" | translate }}
            </div>
          </div>
        </div>
      </button>
      <button class="menu-change-password" mat-menu-item (click)="changePassword()" aria-label="Button changing password">
        <mat-icon svgIcon="icon-lock-reset"></mat-icon>
        {{ "Menu.ChagePassword" | translate }}
      </button>
      <mat-divider></mat-divider>
      <button 
       mat-menu-item class="menu-logout" (click)="onDialogConfirmLogout()">
        <mat-icon class="svg-path svg-fill danger" svgIcon="icon-logout"></mat-icon>
        {{ "Logout" | translate }}
      </button>
    </mat-menu>
  </div>
</div>
