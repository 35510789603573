<div class="alert-icon-dialog">
  <h3 class="mat-h3" matDialogTitle>{{ title | translate }}</h3>
</div>
<div mat-dialog-content align="center">
  <img class="confirm-img" [src]="icon ? icon : '../../../assets/svgs/confirm-img.svg'" alt="Confirm image">
  <p>{{ message | translate: param }}</p>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button matDialogClose="cancel" color="primary" class="btn-cancel btn-sm">
    {{ 'ActionBtn.Cancel' | translate }}
  </button>
  <button *ngIf="button === 'delete'" mat-flat-button matDialogClose="confirm" color="primary"
    class="btn-sm btn-delete">
    {{ 'ActionBtn.Delete' | translate }}
  </button>
  <button *ngIf="button === 'save'" mat-flat-button matDialogClose="confirm" color="warn" class="btn-sm btn-save">
    <mat-icon>save</mat-icon>
    {{ 'ActionBtn.Save' | translate }}
  </button>
  <button *ngIf="button === 'confirm'" mat-flat-button matDialogClose="confirm" color="warn" class="btn-sm btn-save">
    <mat-icon>check_circle_outline</mat-icon>
    {{ 'ActionBtn.Confirm' | translate }}
  </button>
</div>