import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageLayoutComponent } from './page-layout.component';
import { BreadcrumbModule } from '../breadcrumb/breadcrumb.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
  declarations: [
    PageLayoutComponent
  ],
  imports: [
    CommonModule,
    BreadcrumbModule,
    TranslateModule,
    MatIconModule,
    MatToolbarModule
  ],
  exports: [
    PageLayoutComponent
  ]
})
export class PageLayoutModule { }
