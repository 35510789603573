<div class="photo-thumbnail">
  <div class="background">
    <img class="bg-img" [src]="data.photos[currentIndex] + '?token=' + token" />
    <div class="overlay"></div>
  </div>

  <div class="image">
    <div class="prev" *ngIf="data?.photos?.length > 1" (click)="onPrev()">
      <div class="btn-prev">
        <mat-icon>
          keyboard_arrow_left
        </mat-icon>
      </div>
    </div>
    <div class="img">
      <img
        class="inner-img"
        [src]="data.photos[currentIndex] + '?token=' + token"
      />
    </div>
    <div class="next" *ngIf="data?.photos?.length > 1" (click)="onNext()">
      <div class="btn-next">
        <mat-icon>
          keyboard_arrow_right
        </mat-icon>
      </div>
    </div>
  </div>
  <div class="btn-close" (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </div>
</div>
