<div class="sidenav-wrap" [ngClass]="showNav ? 'lg-width' : 'sm-width'">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="sidebar">
    <mat-tree-node
      [routerLink]="node.link"
      routerLinkActive="active"
      *matTreeNodeDef="let node" matTreeNodeToggle
    >
      <div class="left-edge"></div>
      <mat-icon
        class="sn-pref-icon"
        svgIcon="{{ node.icon.name }}" class="{{ node.icon.class }}"
        matTooltip="{{ showNav ? '' : node.name | translate }}"
        matTooltipPosition="right"
      ></mat-icon>
      <div *ngIf="showNav">{{ node.name | translate }}</div>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node
      matTreeNodeToggle
      *matTreeNodeDef="let node; when: hasChild"
    >
      <div class="mat-tree-node" [ngStyle]="{'display': showNav ? '' : 'none'}">
        <div class="left-edge"></div>
        <mat-icon
          class="sn-pref-icon"
          svgIcon="{{ node.icon.name }}"
          class="{{ node.icon.class }}"
          matTooltip="{{ showNav ? '' : node.name | translate }}"
          matTooltipPosition="right"
        ></mat-icon>
        <div *ngIf="showNav">{{ node.name | translate }}</div>
        <button *ngIf="showNav" class="arrow-expand" mat-icon-button matTreeNodeToggle
          [attr.aria-label]="'Toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
          </mat-icon>
        </button>
      </div>
      <div [class.sidebar-invisible]="!treeControl.isExpanded(node) && showNav" role="group">
        <ng-container matTreeNodeOutlet></ng-container>
      </div>
    </mat-nested-tree-node>
  </mat-tree>
  <mat-divider></mat-divider>
  <div
    class="sidenav-logout"
    (click)="onDialogConfirmLogout()"
  >
    <mat-icon
      class="sidebar-icon-logout"
      svgIcon="icon-logout"
      matTooltip="{{ showNav ? '' : 'Logout'|translate }}"
      matTooltipPosition="right"
    ></mat-icon>
    <ng-container *ngIf="showNav">
      {{'Logout'|translate}}
    </ng-container>
  </div>
</div>
