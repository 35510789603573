import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { GenderEnum } from 'src/app/models/enums/gender.enum';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PhotoViewerComponent } from 'src/app/shares/custom-carousel/photo-viewer/photo-viewer.component';

@Component({
  selector: 'app-family-member-tab',
  templateUrl: './family-member-tab.component.html',
  styleUrls: ['../household-head-tab/household-head-tab.component.scss']
})

export class FamilyMemberTabComponent implements OnInit {
  genderEnum = GenderEnum;
  token: string = '';

  @Input() farmer: any;

  constructor(
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.token = this.localStorageService.get(LocalStorageEnum.token);
  }

  onViewImage(photo_url): void {
    if (!photo_url) {
      return
    }
    this.dialog.open(PhotoViewerComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'full-screen-modal',
      data: {
        index: 0,
        photos: photo_url
          ? [photo_url]
          : ['/assets/svgs/avatar-person-image.svg'],
      },
    })
  }
}