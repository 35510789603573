<app-header (toggleShowNav)="toggleShowNav()"></app-header>
<mat-sidenav-container class="layout" autosize>
  <mat-sidenav #drawer class="layout-sidebar" [ngClass]="showNav ? 'lg-width' : 'sm-width'" role="navigation"
    mode="side" disableClose="true" opened="true">
  <app-sidebar [showNav]="showNav"></app-sidebar>
  </mat-sidenav>
  <mat-sidenav-content class="layout-content" role="main">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
