<form [formGroup]="form" autocomplete="off" (ngSubmit)="onSubmit()">
  <div class="dialog-header">
    <h3 class="mat-h3" mat-dialog-title>{{'ProfilePage.EditProfile' | translate}}</h3>
  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-content>
    <div class="form-group">
      <div class="row">
        <div class="col-sm-12">
          <div class="pr-wrap">
            <button disabled mat-icon-button type="button">
              <img mat-card-avatar alt="Avatar image" class="avtar-icon"
                [src]="imgUrl ? imgUrl : profile?.user?.photo_url ? profile?.user?.photo_url + '?token=' + token : '/assets/svgs/avatar-person.svg'"
                onerror="this.src='/assets/svgs/avatar-person.svg'" style="margin-right: unset;" />
            </button>
            <div class="pr-name">
              <div>{{profile?.user | fullName: '' | uppercase}}</div>
              <label for="fileInput" class="change-pic">{{'ProfilePage.ChangePic' | translate}}</label>
              <input id="fileInput" type="file" name="file" (change)="onSelectFile($event)"
                accept="image/png,image/gif,image/jpeg,image/jpg,image/bmp">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <mat-label>
            {{'UserInfo.LastName' | translate}}
            <span class="required"></span>
          </mat-label>
          <mat-form-field class="form-control" appearance="outline">
            <input matInput formControlName="last_name">
            <mat-error *ngIf="form.get('last_name')?.hasError('required')">
              {{ 'ValidationErr.Required' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-label>
            {{'UserInfo.FirstName' | translate}}
            <span class="required"></span>
          </mat-label>
          <mat-form-field class="form-control" appearance="outline">
            <input matInput formControlName="first_name">
            <mat-error *ngIf="form.get('first_name')?.hasError('required')">
              {{ 'ValidationErr.Required' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="btn-md btn-cancel" type="button" mat-stroked-button color="primary" (click)="onCancel()"
      mat-dialog-close>
      <mat-icon svgIcon="icon-cancel"></mat-icon>
      {{'ActionBtn.Cancel' | translate}}
    </button>
    <button class="btn-md" type="submit" mat-flat-button color="primary" cdkFocusInitial>
      <mat-icon class="svg-path svg-fill" svgIcon="icon-bookmark"></mat-icon>
      {{'ActionBtn.Save' | translate }}
    </button>
  </div>
</form>
<div *ngIf="isLoading" class="app-loading"></div>
