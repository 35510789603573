import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MustMatch } from 'src/app/helpers/must-match';
import { RequestErrorEnum } from 'src/app/models/enums/request-error.enum';
import { ProfileService } from 'src/app/services/profile.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  form: FormGroup;
  isLoading: boolean = false;
  hideCurrentPassword: boolean = true;
  hideNewPassword: boolean = true;
  hideConfirmPassword: boolean = true;
  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService,
    private snackBarService: SnackbarService,
    private dialogRef: MatDialogRef<ChangePasswordComponent>
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      current_password: [null, [Validators.required, Validators.minLength(8)]],
      new_password: [null, [Validators.required, Validators.minLength(8)]],
      confirm_password: [null]
    },
      {
        validators: MustMatch('new_password', 'confirm_password'),
      });
  }

  onSubmit() {
    if (this.form.valid) {
      this.isLoading = true;
      const formValue = this.form.value;
      const updateData = {
        current_password: formValue.current_password,
        new_password: formValue.new_password
      }

      this.profileService.changePassword(updateData).subscribe(
        (res) => {
          this.isLoading = false;
          if (res) {
            this.snackBarService.onShowSnackbar('Status.UpdateSuccess');
            this.dialogRef.close(formValue);
          } else {
            this.snackBarService.onShowSnackbar('Status.Failed', false);
          }
        },
        (err) => {
          console.error(err);
          this.isLoading = false;
          if (err && err.error_type === RequestErrorEnum[403]) {
            this.snackBarService.onShowSnackbar("Status.IncorrectCurrentPasswd", false);
          } else {
            this.snackBarService.onShowSnackbar('Status.SomethingWrong', false);
          }
        }
      );
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
