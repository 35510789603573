<table class="farmer-table" mat-table [dataSource]="dataCollection">
  <ng-container matColumnDef="id_card">
    <th mat-header-cell *matHeaderCellDef class="header-center" [ngStyle]="{'display': 'none'}">
      {{ "FarmerPage.IdCard" | translate }}
    </th>
    <td mat-cell *matCellDef="let element;">
      {{element.id_card}}
    </td>
  </ng-container>
  <ng-container matColumnDef="f_id_card">
    <th mat-header-cell *matHeaderCellDef width="100" [attr.rowspan]="2">{{ "FarmerPage.IdCard" | translate }}</th>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}">
      {{ "UserInfo.Name" | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.last_name }} {{ element.first_name }}
    </td>
  </ng-container>
  <ng-container matColumnDef="f_name">
    <th mat-header-cell *matHeaderCellDef width="110" [attr.rowspan]="2">{{ "Dashboard.FarmerName" | translate }}</th>
  </ng-container>

  <ng-container matColumnDef="dob">
    <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}">
      {{ "UserInfo.DateOfBirth" | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.dob | amDateFormat: "DD / MMM / YYYY" }}
    </td>
  </ng-container>
  <ng-container matColumnDef="f_dob">
    <th mat-header-cell *matHeaderCellDef width="110" [attr.rowspan]="2">{{ "UserInfo.DateOfBirth" | translate }}</th>
  </ng-container>

  <ng-container matColumnDef="photo">
    <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}">
      {{ "UserInfo.Photo" | translate }}
    </th>
    <td mat-cell class="td-profile pt-2" *matCellDef="let element" align="center">
      <img *ngIf="element?.photo_url" [src]="element?.photo_url ? getImageUrl(element?.photo_url) : 'assets/svgs/noun-drop-image.svg'"
        onerror="this.src='assets/svgs/noun-drop-image.svg'"
        width="80px"
        height="95px"
      >
    </td>
  </ng-container>
  <ng-container matColumnDef="f_photo">
    <th mat-header-cell *matHeaderCellDef width="50" [attr.rowspan]="2">{{ "UserInfo.Photo" | translate }}</th>
  </ng-container>

  <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumns | slice:4:8; let i = index;">
    <th mat-header-cell *matHeaderCellDef width="70" class="header-center">{{ col | translate }}</th>
    <td mat-cell *matCellDef="let element;" class="py-0">
      {{ getFarmerAddressForDisplay(element?.address, i) }}
    </td>
  </ng-container>
  <ng-container [matColumnDef]="group" *ngFor="let group of columnGroups | slice:4:5">
    <th mat-header-cell *matHeaderCellDef [attr.colspan]="4" class="header-center">{{ group | translate }}</th>
  </ng-container>

  <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumns | slice:8:11; let i = index;">
    <th mat-header-cell *matHeaderCellDef [width]="i != 2 ? 70 : 90" class="header-center">{{ col | translate }}</th>
    <td mat-cell *matCellDef="let element;" class="py-0">
      <table class="mat-like-table align-self-stretch">
        <tr *ngFor="let farmland of element.farmlands; let idx = index;">
          <td ngClass="{{idx < element.farmlands.length - 1 ? 'row-border' : ''}}" class="py-3 px-0">
            {{ getFarmlandTypeLabel(i, farmland) }}
          </td>
        </tr>
      </table>
    </td>
  </ng-container>

  <!-- Farmland info header -->
  <ng-container [matColumnDef]="group" *ngFor="let group of columnGroups | slice:5:6">
    <th mat-header-cell *matHeaderCellDef [attr.colspan]="3" class="header-center">{{ group | translate }}</th>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}">
      {{ "UsersPage.Action" | translate }}
    </th>
    <td mat-cell *matCellDef="let element" align="center">
      <div class="action-container">
        <button
          class="btn-detail"
          aria-label="View"
          mat-icon-button
          [routerLink]="getRouterLink(element._id)"
          matTooltip="{{ 'Tooltip.View' | translate }}"
        >
          <mat-icon svgIcon="icon-view"></mat-icon>
        </button>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="f_action">
    <th mat-header-cell *matHeaderCellDef width="80" [attr.rowspan]="2">{{ "UsersPage.Action" | translate }}</th>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnGroups"></tr>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>