<div>
  <ng-container>
    <h3 class="source-title sub-title-content mt-2">
      {{ "FarmerDetail.Plan" | translate }} {{ farmer?.source }}
    </h3>
    <div class="row pt-2">
      <div class="col-2 caption-sm mb-10">
        {{ "FarmerPage.ClusterNumber" | translate }}:
      </div>
      <div class="col-10 mb-10">
        {{
          farmerInfo?.farmer_id && farmerInfo?.farmer_id !== ""
          ? farmerInfo?.farmer_id
          : ("N/A" | translate)
        }}
      </div>

      <div class="col-2 caption-sm mb-10">
        {{ "FarmerPage.GroupID" | translate }}:
      </div>
      <div class="col-10 mb-10">
        {{
          farmerInfo?.group_id && farmerInfo?.group_id !== ""
          ? farmerInfo?.group_id
          : ("N/A" | translate)
        }}
      </div>

      <div class="col-2 caption-sm mb-10">
        {{ "FarmerPage.ExMember" | translate }}:
      </div>
      <div class="col-10 mb-10">
        {{
          farmerInfo?.ex_member && farmerInfo?.ex_member !== ""
          ? farmerInfo?.ex_member
          : ("N/A" | translate)
        }}
      </div>

      <div class="col-2 caption-sm mb-10">
        {{ "FarmerPage.Cluster" | translate }}:
      </div>
      <div class="col-10 mb-10">
        {{
          farmerInfo?.primary_work && farmerInfo?.primary_work !== ""
          ? farmerInfo?.primary_work
          : ("N/A" | translate)
        }}
      </div>

      <div class="col-2 caption-sm mb-10">
        {{ "FarmerPage.Cluster_n" | translate }}:
      </div>
      <div class="col-10 mb-10">
        {{
          farmerInfo?.secondary_work && farmerInfo?.secondary_work !== ""
          ? farmerInfo?.secondary_work
          : ("N/A" | translate)
        }}
      </div>

      <div class="col-2 caption-sm mb-10">
        {{
          farmer?.source == farmerSourceEnum.ASPIRE
          ? ("FarmerPage.JoinASPRE" | translate)
          : ("FarmerPage.JoinAims" | translate)
        }}:
      </div>
      <div class="col-10 mb-10">
        {{
          farmerInfo?.aspire_aim_member && farmerInfo?.aspire_aim_member !== ""
          ? (farmerInfo.aspire_aim_member | amDateFormat: "DD / MMM / YYYY")
          : ("N/A" | translate)
        }}
      </div>
    </div>
  </ng-container>
</div>