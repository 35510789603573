import { Component, Inject, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';

import { LocalStorageService } from 'src/app/services/local-storage.service';
import { TokenPipe } from 'src/app/shares/token-pipe/pipes/token.pipe';

const addresses = ['village', 'commune', 'district', 'province'];

@Component({
  selector: 'app-farmer-table',
  templateUrl: './farmer-table.component.html',
  styleUrls: ['./farmer-table.component.scss']
})

export class FarmerTableComponent implements OnInit {
  currentLanguage: string = 'km';
  dateFormat = 'YYYY-MM-DD';
  displayedColumns: string[] = [
      'id_card',
      'name',
      'dob',
      'photo',
      'address',
      'farmland',
      'action'
    ];
  columnGroups: string[] = ['f_id_card', 'f_name', 'f_dob', 'f_photo', 'ProductPage.Address', 'Dashboard.FarmlandInfo', 'f_action'];
  trans: string[] = [];

  @Input() dataCollection: Array<any>;
  @Input() prefixLink: string;

  constructor(
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.currentLanguage = this.translate.currentLang;
    this.displayedColumns = [
      "id_card",
      'name',
      'dob',
      'photo',
      'FarmerPage.Village',
      'FarmerPage.CommuneSk',
      'FarmerPage.DistrictKh',
      'FarmerPage.Provice',
      'FarmlandPage.LandCode',
      'FarmlandPage.LandType',
      'FarmlandPage.LandSize',
      'action'
    ];

    this.getTranslations();
  }

  async getTranslations(): Promise<void> {
    this.trans = await this.translate
      .get([
        'No',
        'N/A',
        'Location.Location',
        'FarmlandPage.LandCode',
        'FarmlandPage.LandType',
        'FarmlandPage.LandSize',
        'FarmlandEnum.Type.PLANTATOIN',
        'FarmlandEnum.Type.RICE',
        'FarmlandEnum.Type.NET_HOUSE',
        'FarmlandEnum.Type.GREEN_HOUSE',
        'FarmlandEnum.Type.PLANTATION',
        'UnitType.Acres',
        'Address.Khan',
        'Address.Sangkat',
        'Address.District',
        'Address.Village',
        'Address.Commune',
        'Address.Province'
      ])
      .toPromise();
  }

  getFarmerAddressForDisplay(address, index) {
    return _.get(address, this.currentLanguage == 'en' ? `${addresses[index]}.name_en` : `${addresses[index]}.name`, this.trans['N/A']);
  }

  getFarmlandTypeLabel(index, farmland) {
    switch (index) {
      case 0:
        return farmland.code;
        break;
      case 1:
        return this.trans['FarmlandEnum.Type.' + farmland.type];
        break;
      default:
        return farmland.land_size + ' ' + this.trans['UnitType.Acres'];
    }
  }

  getImageUrl(imageUrl) {
    if (!imageUrl)
      return null;

    return new TokenPipe(this.localStorageService).transform(imageUrl);
  }

  getRouterLink(id) {
    if (!!this.prefixLink)
      return this.prefixLink + id;

    return id;
  }
}