<div class="mt-4">
  <h3 class="sub-title-content pt-2">
    {{ "Dashboard.FarmlandInfo" | translate }}
  </h3>

  <div class="row">
    <div class="col-8">
      <table class="farmer-table" mat-table [dataSource]="farmlands">
        <ng-container matColumnDef="code">
          <th mat-header-cell width="115" *matHeaderCellDef class="header-center">
            {{ "FarmlandPage.LandCode" | translate }}
          </th>
          <td mat-cell *matCellDef="let element;">
            {{element.code || ("N/A" | translate)}}
          </td>
        </ng-container>
    
        <ng-container matColumnDef="type">
          <th mat-header-cell width="100" *matHeaderCellDef class="header-center">
            {{ "FarmlandPage.LandType" | translate }}
          </th>
          <td mat-cell *matCellDef="let element;">
            {{
              element.type
              ? ("FarmlandEnum.Type." + element.type | translate)
              : ("N/A" | translate)
            }}
          </td>
        </ng-container>
    
        <ng-container matColumnDef="purpose">
          <th mat-header-cell width="200" *matHeaderCellDef class="header-center">
            {{ "FarmlandPage.Purpose" | translate }}
          </th>
          <td mat-cell *matCellDef="let element;">
            {{
              element.purpose
              ? ("FarmlandEnum.Purpose." + element.purpose | translate)
              : ("N/A" | translate)
            }}
          </td>
        </ng-container>
    
        <ng-container matColumnDef="size">
          <th mat-header-cell width="160" *matHeaderCellDef class="header-center">
            {{ "FarmlandPage.LandSize" | translate }}
          </th>
          <td mat-cell *matCellDef="let element;">
            {{
              element.land_size
              ? (element.land_size + " " + ("UnitType.Acres" | translate))
              : ("N/A" | translate)
            }}
          </td>
        </ng-container>
    
        <ng-container matColumnDef="width">
          <th mat-header-cell width="120" *matHeaderCellDef class="header-center">
            {{ "FarmerDetail.Width" | translate }}
          </th>
          <td mat-cell *matCellDef="let element;">
            {{
              element.width
              ? (element.width + " " + ("Meter" | translate))
              : ("N/A" | translate)
            }}
          </td>
        </ng-container>
    
        <ng-container matColumnDef="length">
          <th mat-header-cell width="120" *matHeaderCellDef class="header-center">
            {{ "FarmerDetail.Length" | translate }}
          </th>
          <td mat-cell *matCellDef="let element;">
            {{
              element.length
              ? (element.length + " " + ("Meter" | translate))
              : ("N/A" | translate)
            }}
          </td>
        </ng-container>
    
        <ng-container matColumnDef="irrigation_length">
          <th mat-header-cell width="200" *matHeaderCellDef class="header-center">
            {{ "FarmlandPage.TotalIrrigationLength" | translate }}
          </th>
          <td mat-cell *matCellDef="let element;">
            {{
              element.total_irrigation_length
              ? element.total_irrigation_length + ' ' + ("Meter" | translate)
              : ("N/A" | translate)
            }}
          </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    </div>
    <div class="col-4">
      <div *ngIf="apiLoaded | async" style="position: relative;">
        <google-map class="farmland-map" width="100%" [options]="options">
          <map-marker *ngFor="let position of markerPositions;" [position]="position"
            icon="../../../../../assets/svgs/icon-marker.svg"
            (mapClick)="dialogDisplay(position)"
          ></map-marker>
        </google-map>
        <div class="farmland-map-dialog" [ngStyle]="{ display: styleDisplay }">
          <div class="d-flex">
            <div class="container-fluid px-0" style="align-content: center;">
              <div>
                <span style="color: #979797">{{ "FarmlandPage.LandCode" | translate }}: </span>
                <span>{{ selectedFarmland.label || ("N/A" | translate) }}</span>
              </div>
              <div class="mt-2">
                <span style="color: #979797">{{ "FarmerDetail.CreatedAt" | translate }}: </span>
                <span>{{ selectedFarmland.created_at | amDateFormat: "DD / MMM / YYYY" }}</span>
              </div>
            </div>
            <div style="margin-top: -10px; margin-right: -10px;">
              <button (click)="hideDialog()" mat-icon-button aria-label="cross icon">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>