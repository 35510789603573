import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

// config sentry for grabing error logs
function applySentry(): void {
  Sentry.init({
    // dsn: "https://576a648084d34e269b5cfc34c64b6e9b@sentry.optistech.com/14",
    dsn: environment.sentry_dsn,
    environment: environment.env_name === 'production' ? "production" : environment.env_name ===  'staging' ? "staging" : 'development',
    integrations: [
      new BrowserTracing({
        tracingOrigins: [environment.host_url],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new CaptureConsoleIntegration({
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ['error']
      })
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

if (environment.production && environment.env_name === "production") {
  applySentry();
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
