import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseResponse } from 'src/app/models/responses/base.response';
import { BaseCrudService } from 'src/app/services/base-crud.service';
import { RequestService } from 'src/app/services/request.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportService extends BaseCrudService<null> {
  protected path: string;
  protected basePath: string;
  protected requestService: RequestService;

  constructor(injector: Injector) {
    super(injector);
    this.path = environment.weather_url + '/reports/collection-report';
    this.requestService = injector.get(RequestService);
  }

  /**
   * Get Report Collection
   * @returns Json
   */
  getCollectionReport(data: {}): Observable<BaseResponse> {
    return this.requestService.get(this.path, { data });
  }

  /**
   * Get Farmer Report Collection
   * @returns Json
   */
  getFarmerCollectionReport(_id, data: {}): Observable<BaseResponse> {
    return this.requestService.get(this.path + '/' + _id + '/farmers', {
      data,
    });
  }

  /**
   * Get Soil Report Collection
   * @returns Json
   */
  getSoilCollectionReport(_id, data: {}): Observable<BaseResponse> {
    return this.requestService.get(this.path + '/' + _id + '/soils', { data });
  }

  getCropCycle(data: {}): Observable<BaseResponse> {
    return this.requestService.get(
      environment.kumrong_url + '/reports/cycles', {data}
    );
  };

  getNutrients(): Observable<BaseResponse> {
    return this.requestService.get(
      environment.weather_url + '/soil-samples?includes=nutrient'
    );
  }

  getFarmerInfo(_id): Observable<BaseResponse> {
    return this.requestService.get(
      environment.resource_url + '/farmers/' + _id
    );
  }

  getFarmlandDetail(farmerID, farmlandID): Observable<BaseResponse> {
    let url = '/farmers/' + farmerID + '/farmlands/' + farmlandID;
    return this.requestService.get(
      environment.kumrong_url + url
    );
  }

  getFarmland(data: {}) : Observable<BaseResponse> {
    return this.requestService.get(
      environment.kumrong_url + '/reports/farmlands', {data}
    );

  }

  getReportByEntrepreneur(data: {}) : Observable<BaseResponse> {
    return this.requestService.get(this.path + '/entrepreneurs', {data});
  }
}
