import { BaseCrudService } from './base-crud.service';
import { Injectable, Injector } from '@angular/core';
import { Profile, User } from '../models/profile';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends BaseCrudService<Profile> {

    constructor(injector: Injector) {
        super(injector);
        this.path = environment.weather_url + '/profile';
    }

    changePassword(data: {}) {
        return this.requestService.postJSON(
            this.path + '/change-password', { data }
        );
    }

    updateProfile(data: {}) {
        return this.requestService.putJSON(
            this.path, {data}
        );
    }

    uploadProfilePhoto(data: {}) {
        return this.requestService.postFile<User>(
            this.path + '/photo', { data: data, option: { is_loading: true } }
        );
    }
}