import { Component, Input } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { SidenavMenu, SidenavNode } from './sidenav-menu';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/shares/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import _ from 'lodash';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

  @Input() showNav: boolean = true;
  authSubscribe;
  isAuth: boolean;
  treeControl = new NestedTreeControl<SidenavNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<SidenavNode>();
  hasChild = (_: number, node: SidenavNode) => !!node.children && node.children.length > 0;

  constructor(
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.dataSource.data = this.getSideNavByRole();
    this.authSubscribe = authService.authChange$.subscribe(isAuth => this.isAuth = isAuth);
  }

  // filter SidenavMenu based on user role permission
  getSideNavByRole() {
    return _.filter(SidenavMenu, sm => {
      let child = _.filter(sm.children, ch => this.authService.hasAccess(ch.roles));
      sm.children = child;
      return this.authService.hasAccess(sm.roles);
    });
  }

  logout(): void {
    this.isAuth = false;
    this.authSubscribe.unsubscribe();
    this.authService.logout();
    window.location.replace("/login");
  }

  onDialogConfirmLogout(): void {
    const ref = this.dialog.open(ConfirmDialogComponent,
      {
        panelClass: 'small-modal',
        data: {
          title: 'Logout',
          icon: 'assets/svgs/mobile-logout.svg',
          message: 'ConfirmMessage.Logout',
          button: 'confirm'
        }
      },
    );
    ref.afterClosed().subscribe(
      (res) => {
        if (res == 'confirm') {
          this.logout();
        }
      }
    );
  }
}
