import { BaseCrudService } from './base-crud.service';
import { Injectable, Injector } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { BaseResponse } from 'src/app/models/responses/base.response';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserWeatherService extends BaseCrudService<User> {

    constructor(injector: Injector) {
        super(injector);
        this.path = environment.weather_url + '/users';
    }

  resetPassword(_id: string, data: {}) {
    return this.requestService.putJSON(
      this.path + '/' + _id + '/reset-password', { data }
    );
  }
  getUserByOperatorID(data: {}): Observable<BaseResponse> {
    return this.requestService.get(this.path + '/operators', {data});
  }

  getUserDetail(_id): Observable<BaseResponse> {
    return this.requestService.get(
      this.path + '/' + _id
    );
  }

  getWeatherProfile(): Observable<BaseResponse> {
    return this.requestService.get(
      environment.weather_url + '/profile'
    );
  }
}
