import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-row',
  templateUrl: './info-row.component.html',
  styleUrls: ['./info-row.component.scss']
})

export class InfoRowComponent {
  constructor() {}

  @Input() label: string;
  @Input() value: any;
}