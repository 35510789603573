export enum LocalStorageEnum {
  token = '_t',
  user_id = '_u',
  user_roles = '_r',
  operator_id = '_oid',
  province_id = '_pid',
  district_id = '_did',
  commune_id = '_cid',
  village_id = '_vid',
  user_fullname = '_ufn',
  user_photo_url = '_upu',
  refresh_token = '_rft',
  data_collection_filter = '_dcf',
  data_collection_detail_filter = '_dcdf',
  data_collection_detail_tab = '_dcdt',
  farmer_table_filter = '_ftf'
}
