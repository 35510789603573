import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseResponse } from '../models/responses/base.response';
import { BaseCrudService } from './base-crud.service';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class AddressService extends BaseCrudService<null> {
  protected path: string;
  protected requestService: RequestService;

  constructor(injector: Injector) {
    super(injector);
    this.path = environment.weather_url;
    this.requestService = injector.get(RequestService);

  }
  getAllProvince(): Observable<BaseResponse>{
    return this.requestService.get(this.path + '/provinces');
  }

  getDistrictsByProvince(province_id): Observable<BaseResponse>{
    return this.requestService.get(this.path + '/districts?province=' + province_id);
  }

  getCommunesByDistrict(district_id): Observable<BaseResponse>{
    return this.requestService.get(this.path + '/communes?district=' + district_id );
  }

  getVillagesByCommune(commune_id): Observable<BaseResponse>{
    return this.requestService.get(this.path + '/villages?commune=' + commune_id);
  }
}
