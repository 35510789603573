import { Component, Input, OnInit } from '@angular/core';

import { FarmerSourceEnum } from 'src/app/models/enums/farmer-source.enum';

@Component({
  selector: 'app-project-tab',
  templateUrl: './project-tab.component.html',
  styleUrls: ['../household-head-tab/household-head-tab.component.scss']
})

export class ProjectTabComponent implements OnInit {
  @Input() farmer: any;

  farmerSourceEnum = FarmerSourceEnum;
  farmerInfo: any = {};

  ngOnInit(): void {
    if (this.farmer?.source == this.farmerSourceEnum.ASPIRE) {
      this.farmerInfo = {
        ...this.farmer?.aspire_household_head,
        farmer_id: this.farmer?.aspire_household_head?.aspire_farmer_id,
      };
    }
    else if (this.farmer?.source == this.farmerSourceEnum.AIMS) {
      this.farmerInfo = {
        ...this.farmer?.aims_household_head,
        farmer_id: this.farmer?.aims_household_head?.aims_farmer_id,
      };
    }
  }
}