import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Commune, District, Province, Village } from 'src/app/models/address';
import { AddressService } from 'src/app/services/address.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-filter-farmers-dialog',
  templateUrl: './filter-farmers-dialog.component.html',
  styleUrls: []
})

export class FilterFarmersDialogComponent implements OnInit {
  form: FormGroup;
  provinces: Province[] = [];
  districts: District[] = [];
  communes: Commune[] = [];
  villages: Village[] = [];
  isLoading: boolean = false;
  disabledProvince: boolean = false;
  disabledDistrict: boolean = false;

  constructor(
    private fb: FormBuilder,
    private addressService: AddressService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<FilterFarmersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.fb.group({
      province: [0],
      district: [0],
      commune: [0],
      village: [0],
      id_card: [''],
      start_date: [''],
      end_date: [''],
      last_name: [''],
      first_name: [''],
    });
  }

  ngOnInit(): void {
    this.getProvinces();
    if (this.authService.isNationalRole() || this.authService.isProvincialRole()
        || this.authService.isDistrictRole()
    ) {
      if (this.data.province)
        this.getDistricts(this.data.province);
      if (this.data.district)
        this.getCommunes(this.data.district);
      if (this.data.commune)
        this.getVillages(this.data.commune);
    }

    this.patchValue();
  }

  getProvinces(): void {
    this.isLoading = true;
    this.addressService.getAllProvince().subscribe(
      (res) => {
        this.provinces = res.data;
        this.isLoading = false;
      },
      (err) => {
        console.error(err);
        this.isLoading = false;
      }
    );
  }

  getDistricts(province_id: number, onSelectionChanged: boolean = false): void {
    this.isLoading = true;
    // clear previous selected value
    if (province_id === 0) {
      this.clearForm({
        district: 0,
        commune: 0,
        village: 0,
      });
      this.districts = [];
      this.communes = [];
      this.villages = [];
    }

    this.addressService.getDistrictsByProvince(province_id).subscribe(
      (res) => {
        this.districts = res.data;
        this.isLoading = false;
        if (this.data.commune > 0 && onSelectionChanged === true) {
          this.communes = [];
          this.villages = [];
        }
      },
      (err) => {
        console.error(err);
        this.isLoading = false;
      }
    );
  }

  getCommunes(district_id: number, onSelectionChanged: boolean = false): void {
    this.isLoading = true;
    // clear previous selected value
    if (district_id === 0) {
      this.clearForm({
        commune: 0,
        village: 0,
      });
      this.communes = [];
      this.villages = [];
    }

    this.addressService.getCommunesByDistrict(district_id).subscribe(
      (res) => {
        this.communes = res.data;
        this.isLoading = false;
        if (this.data.village > 0 && onSelectionChanged === true)
          this.villages = [];
      },
      (err) => {
        console.error(err);
        this.isLoading = false;
      }
    );
  }

  getVillages(commune_id: number): void {
    this.isLoading = true;
    // clear previous selected value
    if (commune_id === 0) {
      this.clearForm({ village: 0 });
      this.villages = [];
    }

    this.addressService.getVillagesByCommune(commune_id).subscribe(
      (res) => {
        this.villages = res.data;
        this.isLoading = false;
      },
      (err) => {
        console.error(err);
        this.isLoading = false;
      }
    );
  }

  patchValue(): void {
    this.form.patchValue({
      province: this.data.province,
      id_card: this.data.id_card,
      start_date: this.data.start_date,
      end_date: this.data.end_date,
      first_name: this.data.first_name,
      last_name: this.data.last_name,
      district: this.data.district,
      village: this.data.village,
      commune: this.data.commune,
    });

    if (this.authService.isNationalRole()) return;

    if (this.authService.isProvincialRole()) {
      this.disabledProvince = true;
    } else if (this.authService.isDistrictRole()) {
      this.disabledProvince = true;
      this.disabledDistrict = true;
    }
  }

  clearForm(object: {}): void {
    this.form.patchValue(object);
  }

  onResetForm(): void {
    this.form.patchValue({
      province: [0],
      district: [0],
      commune: [0],
      village: [0],
      id_card: [''],
      start_date: null,
      end_date: null,
      last_name: [''],
      first_name: [''],
    });
  }

  onSubmit() {
    if (!this.form.valid) return;

    this.dialogRef.close({ data: this.form.value });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  resetDate(): void {
    this.form.patchValue({
      start_date: null,
      end_date: null
    });
  }
}