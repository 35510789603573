<div>
  <h3 *ngIf="farmer?.family_members.length == 0" class="sub-title-content pt-2">
    {{ "FarmerMemberInfo.NoMemberFarmily" | translate }}
  </h3>

  <ng-container *ngIf="farmer?.family_members.length > 0">
    <div class="row pt-2">
      <div class="col-3 caption-sm mb-10">
        {{ "FarmerPage.MaleFamilyMember" | translate }}:
      </div>
      <div class="col-9 mb-10">
        {{ farmer?.male_family_member }}
      </div>
      <div class="col-3 caption-sm mb-10">
        {{ "FarmerPage.FemaleFamilyMember" | translate }}:
      </div>
      <div class="col-9 mb-10">
        {{ farmer?.female_family_member }}
      </div>
      <div class="col-3 caption-sm mb-10">
        {{ "FarmerPage.AdultFamilyMember" | translate }}:
      </div>
      <div class="col-9 mb-10">
        {{ farmer?.adult_family_member }}
      </div>
    </div>


    <mat-divider class="my-3"></mat-divider>
    <div class="mt-2" *ngFor="let fm of farmer?.family_members; let i = index">
      <h3 class="source-title sub-title-content mt-2">
        {{ "FarmerDetail.Member" | translate }} {{ i + 1 }}
      </h3>
      <div class="col-lg pt-2" style="display: flex;">
        <div style="flex: 1;">
          <app-info-row
            label='{{ "FarmerPage.IdCard" | translate }}'
            value='{{ fm?.id_card || ("N/A" | translate) }}'
          >
          </app-info-row>
          <app-info-row
            label='{{ "FarmerMemberInfo.Name" | translate }}'
            value='{{ fm?.last_name + " " + fm?.first_name || ("N/A" | translate) }}'
          >
          </app-info-row>
          <app-info-row
            label='{{ "FarmerPage.Gender" | translate }}'
            value='{{ fm?.gender ? (genderEnum[fm?.gender] | translate) : ("N/A" | translate) }}'
          >
          </app-info-row>
          <app-info-row
            label='{{ "FarmerPage.Ethnicity" | translate }}'
            value='{{
              fm?.ethnic
              ? ("Ethnic." + fm?.ethnic | translate)
              : ("N/A" | translate)
            }}'
          >
          </app-info-row>
          <app-info-row
            label='{{ "FarmerMemberInfo.DateOfBirth" | translate }}'
            value='{{
              fm?.dob
              ? (fm?.dob | amDateFormat: "DD / MMM / YYYY")
              : ("N/A" | translate)
            }}'
          >
          </app-info-row>
          <app-info-row
            label='{{ "FarmerMemberInfo.Role" | translate }}'
            value='{{
              fm?.family_hierarchy
              ? (fm?.family_hierarchy | translate)
              : ("N/A" | translate)
            }}'
          >
          </app-info-row>
        </div>

        <div style="flex: 1;">
          <div>{{ "FarmerPage.IdentityPhoto" | translate }}</div>
          <div class="wrraper-image" [ngClass]="fm?.id_card_photo_url ? 'wrraper-has-image' : 'wrraper-no-image'">
            <img alt="Avatar image"
              [src]="fm?.id_card_photo_url ? fm?.id_card_photo_url + '?token=' + token : '/assets/svgs/id-image.svg'"
              onerror="this.src='/assets/svgs/id-image.svg'"
              (click)="onViewImage(fm?.id_card_photo_url)"
            />
          </div>
        </div>
      </div>
      <mat-divider class="my-4"></mat-divider>
    </div>
  </ng-container>
</div>