import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { Pagination } from 'src/app/models/pagination';
import { FarmersService } from './services/farmers.service';
import { TokenPipe } from 'src/app/shares/token-pipe/pipes/token.pipe';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { FilterFarmersDialogComponent } from './components/filter-farmer-dialog/filter-farmers-dialog.component';

@Component({
  selector: 'app-farmers',
  templateUrl: './farmers.component.html',
  styleUrls: ['./farmers.component.scss']
})

export class FarmersComponent implements OnInit {
  isLoading: Boolean = false;
  dataCollection: Array<any>;
  isEntrepreneurRole: boolean = false;
  currentLanguage: string = 'km';
  paginationData = {
    per_page: 10,
    current_page: 1,
    total: 10,
  };
  dateFormat = 'YYYY-MM-DD';
  filterData = {
    page_number: 1,
    per_page: 10,
    start_date: null,
    end_date: null,
    province: 0,
    district: 0,
    commune: 0,
    village: 0,
    id_card: null,
    first_name: null,
    last_name: null,
    includes: ["farmland"]
  };
  displayedColumns: string[] = [
    'id_card',
    'name',
    'dob',
    'photo',
    'address',
    'farmland',
    'action'
  ];
  columnGroups: string[] = ['f_id_card', 'f_name', 'f_dob', 'f_photo', 'f_address', 'Dashboard.FarmlandInfo', 'f_action'];
  trans: string[] = [];

  constructor(
    private translate: TranslateService,
    private service: FarmersService,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    let currentUrl = this.router.url;
    let previousUrl = '';
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        previousUrl = currentUrl;
        currentUrl = event.url;
        if (currentUrl == '/farmers' && !previousUrl.startsWith('/farmers/')) {
          this.localStorageService.delete(LocalStorageEnum.farmer_table_filter);
        }
      }
    });

    // Clear the selected filter value when closing browser tab or logging out
    window.onbeforeunload = () => {
      this.localStorageService.delete(LocalStorageEnum.farmer_table_filter);
    }

    this.currentLanguage = this.translate.currentLang;
    this.displayedColumns = [
      "id_card",
      'name',
      'dob',
      'photo',
      'address',
      'FarmlandPage.LandCode',
      'FarmlandPage.LandType',
      'FarmlandPage.LandSize',
      'action'
    ]
    this.getTranslations();

    let selectedFilter = JSON.parse(this.localStorageService.get(LocalStorageEnum.farmer_table_filter));
    if (!!selectedFilter)
      this.filterData = selectedFilter;

    this.getFarmerList();
  }

  async getTranslations(): Promise<void> {
    this.trans = await this.translate
      .get([
        'No',
        'N/A',
        'Location.Location',
        'FarmlandPage.LandCode',
        'FarmlandPage.LandType',
        'FarmlandPage.LandSize',
        'FarmlandEnum.Type.PLANTATOIN',
        'FarmlandEnum.Type.RICE',
        'FarmlandEnum.Type.NET_HOUSE',
        'FarmlandEnum.Type.GREEN_HOUSE',
        'FarmlandEnum.Type.PLANTATION',
        'UnitType.Acres',
        'Address.Khan',
        'Address.Sangkat',
        'Address.District',
        'Address.Village',
        'Address.Commune',
        'Address.Province'
      ])
      .toPromise();
  }

  getFarmerList(): void {
    this.isLoading = true;
    this.service.getFarmers(this.filterData).subscribe((res) => {
      this.isLoading = false;
      this.dataCollection = res.data.data;
      this.paginationData.per_page = res.data?.pagination?.per_page;
      this.paginationData.total = res.data?.pagination?.total;
    }, (err) => {
      this.isLoading = false;
    });
  }

  resetPagination() {
    this.paginationData.current_page = 1;
    this.paginationData.per_page = 10;
    this.filterData.page_number = 1;
    this.filterData.per_page = 10;
  }

  openFilterDialog(): void {
    const dialogRef = this.dialog.open(FilterFarmersDialogComponent, {
      panelClass: ['medium-modal', 'modal-with-tab'],
      data: this.filterData,
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.resetPagination();
        this.filterData = data.data;
        this.filterData.includes = ["farmland"];
        this.getFarmerList();
        this.localStorageService.set(LocalStorageEnum.farmer_table_filter, JSON.stringify(this.filterData));
      }
    });
  }

  goTo(event: Pagination) {
    this.paginationData.per_page = event.per_page;
    this.paginationData.current_page = event.current_page;
    this.filterData.page_number = event.current_page;
    this.filterData.per_page = event.per_page;
    this.getFarmerList();
  }

  getFarmerAddressForDisplay(address, type) {
    return _.get(address, this.currentLanguage == 'en' ? `${type}.name_en` : `${type}.name`, this.trans['N/A']);
  }

  getFarmlandTypeLabel(index, farmland) {
    switch (index) {
      case 0:
        return farmland.code;
        break;
      case 1:
        return this.trans['FarmlandEnum.Type.' + farmland.type];
        break;
      default:
        return farmland.land_size + ' ' + this.trans['UnitType.Acres'];
    }
  }

  getImageUrl(imageUrl) {
    if (!imageUrl)
      return null;

    return new TokenPipe(this.localStorageService).transform(imageUrl);
  }
}