import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { KumrongService } from 'src/app/services/kumrong.service';
import { AnnualHarvestYieldReport } from 'src/app/models/annual-harvest-yield-report';
import { formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-annual-yield',
  templateUrl: './annual-yield.component.html',
  styleUrls: ['./annual-yield.component.scss']
})
export class AnnualYieldComponent implements OnInit {
  form: FormGroup;

  displayedColumns = [
    'position',
    'crop_name',
    'total_estimation_yield',
    'total_actual_yield'
  ];

  annualYields: AnnualHarvestYieldReport[] = [];
  dataSource = [];
  columnGroups: string[] = [];
  isLoading: boolean = false;

  params = {
    start_date: null,
    end_date: null
  }

  stickyColumns = ['position', 'crop_name', 'total_estimation_yield', 'total_actual_yield'];

  trans: string[] = [];
  // https://stackoverflow.com/questions/42462764/javascript-export-csv-encoding-utf-8-issue
  BOM = '\uFEFF'; // Byte Order Mark,

  constructor(
    private fb: FormBuilder,
    private kumroungService: KumrongService,
    private translateService: TranslateService,
  ) { 
    this.form = this.fb.group({
      startDate: [''],
      endDate: ['']
    })
  }

  async ngOnInit(): Promise<void> {
    this.getTranslations();
    await this._fetchDataAndUpdateTable();
  }
  async getTranslations(): Promise<void> {
    this.trans = await this.translateService
      .get([
        'No',
        'Crop.Name',
        'HarvestEstimation.TotalEstimationYield',
        'HarvestEstimation.TotalActualYield',
        'HarvestEstimation.Estimation',
        'HarvestEstimation.Actual',
        'HarvestEstimation.Note'
      ])
      .toPromise();
  }

  private async _fetchDataAndUpdateTable() {
    try {
      this.isLoading = true;
      this.annualYields = (await this.kumroungService.getAnnualHarvestYieldReport(this.params).toPromise()).data;

      this.resetData();
      this._generateColumnGroups();
      this._generateData();

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.error(error);
    }
  }

  resetData() {
    this.displayedColumns = [
      'position',
      'crop_name',
      'total_estimation_yield',
      'total_actual_yield'
    ];

    this.dataSource = [];
    this.columnGroups = [];
  }

  onDateChange(field: string, event: any) {
    this.params[field] = event.value ? moment(event.value).locale('en').format('YYYY-MM-DD') : null;
    if (
      this.params.start_date && this.params.start_date != '' &&
      this.params.end_date && this.params.end_date != ''
    ) {
      this._fetchDataAndUpdateTable();
    }
  }

  private _generateData() {
    this.dataSource = this.annualYields.map(any => {
      let data = {};

      data['crop_name'] = any.crop?.name_km;
      data['total_estimation_yield'] = formatNumber(any?.total_estimate_harvest_quantity, 'en', '1.0-2');
      data['total_actual_yield'] = formatNumber(any?.total_harvest_quantity, 'en', '1.0-2');

      let eachYears = _.uniq(any.data.map(d => d.year));

      for (let i = 0; i < eachYears.length; i++) {
        let years12 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        years12.forEach((yData, idx) => {
          let dataIdx = any.data.findIndex(dt => dt.month === yData && dt.year === eachYears[i]);

          if (dataIdx != -1) {
            data[`Estimation_${idx}_${eachYears[i]}`] = formatNumber(any.data[dataIdx]?.total_estimate_harvest_quantity, 'en', '1.0-2');//formatNumber(dd?.total_estimate_harvest_quantity / 1000, 'en', '1.0-2');
            data[`Actual_${idx}_${eachYears[i]}`] = formatNumber(any.data[dataIdx]?.total_harvest_quantity, 'en', '1.0-2');
          } else {
            data[`Estimation_${idx}_${eachYears[i]}`] = 0;
            data[`Actual_${idx}_${eachYears[i]}`] = 0;
          }
        });
      }

      return data;
    })
  }

  // Create column group(column group = column colspan) header based on
  // which data length greater than others
  private _generateColumnGroups() {

    if (this.annualYields.length) {

      const dataIndexes = this.annualYields.map(d => d?.data?.length);
      let maxIndex = dataIndexes.indexOf(Math.max(...dataIndexes));
      let eachYears = _.uniq(this.annualYields[maxIndex]?.data?.map(dd => dd.year));

      for (let i = 0; i < eachYears.length; i++) {
        let years12 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        years12.forEach((yData, idx) => {
          this.displayedColumns.push(`Estimation_${idx}_${eachYears[i]}`)
          this.displayedColumns.push(`Actual_${idx}_${eachYears[i]}`)
          this.columnGroups.push(moment().month(idx).format('MMMM') + `_${eachYears[i]}`);
        });

      }

      this.columnGroups = ['no', 'crop_n', 'total_e', 'total_a', ...this.columnGroups];
    }
  }

  exportFile() {
    const colTitle1 = [];

    this.displayedColumns.forEach((dc, i) => {
      let th = this.trans['HarvestEstimation.' + dc.split('_')[0]];

      if (i > 3) {
        colTitle1.push(th)
      } else {
        colTitle1.push('');
      }
    })
    const rows = this.dataSource.map((d, i) => {
      return this.displayedColumns.map((col, idx) => {

        if (col === 'position') {
          return i + 1;
        } else {
          // return d[col] && d[col] != '' ? d[col].replace(',', '') : 0
          return typeof d[col] === 'string' && d[col].includes(',') ? `"${d[col]}"` : `"${d[col] || '0'}"`;
        }

      })
    });

    // Check if there is data available
    if (this.dataSource.length === 0) {
      return;
    }

    rows.unshift(colTitle1); // add column title to the first row

    let colTitle2 = [
      this.trans['No'],
      this.trans['Crop.Name'],
      this.trans['HarvestEstimation.TotalEstimationYield'],
      this.trans['HarvestEstimation.TotalActualYield']
    ];
    this.columnGroups.forEach((item, i) => {
      if (i > 3) {
        colTitle2.push(item.split('_')[0]);
        colTitle2.push(item.split('_')[0]);
      }
    })

    rows.unshift(colTitle2);
    rows.push(['']); // add space in a row above
    rows.push([this.trans['HarvestEstimation.Note']]);

    let csvContent =
      'data:text/csv;charset=utf-8,' +
      this.BOM +
      rows.map((e) => e.join(',')).join('\n');

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    let reportName =
      'annual-yield-' + moment().locale('en').format('YYYY-MM-DD') + '.csv';
    link.setAttribute('download', reportName);
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  onResetForm(startDateInput: HTMLInputElement, endDateInput: HTMLInputElement): void {
    startDateInput.value = '';
    endDateInput.value = '';
    this.params.start_date = null;
    this.params.end_date = null;
    this.form.reset();
    this.clearDateFields();
    this._fetchDataAndUpdateTable();
  }

  private clearDateFields(): void {
    this.params = {
      start_date: null,
      end_date: null
    }
  }

}
