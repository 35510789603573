import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FarmerSourceEnum } from 'src/app/models/enums/farmer-source.enum';
import { ReportService } from '../../report/services/report.service';

@Component({
  selector: 'app-farmer-detail',
  templateUrl: './farmers-detail.component.html',
  styleUrls: ['./farmers-detail.component.scss'],
})

export class FarmersDetailComponent implements OnInit {
  isLoading: boolean = false;
  farmerSourceEnum = FarmerSourceEnum;
  farmer: any;
  farmerId: string = !!this.route.snapshot.paramMap.get('id2') ? this.route.snapshot.paramMap.get('id2') : this.route.snapshot.paramMap.get('id');

  constructor(
    private route: ActivatedRoute,
    private reportService: ReportService,
  ) {}

  ngOnInit(): void {
    this.getFarmerDetail();
  }

  getFarmerDetail(): void {
    this.isLoading = true;
    this.reportService.getFarmerInfo(this.farmerId).subscribe(
      (res) => {
        this.farmer = res.data;
        this.isLoading = false;
      },
      (err) => {
        console.error(err);
        this.isLoading = false;
      }
    );
  }
}