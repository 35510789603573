import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Farmland } from 'src/app/models/farmland';
import { environment } from 'src/environments/environment';

interface FarmlandLatLngLiteral extends google.maps.LatLngLiteral {
  label: string,
  created_at: string
}

@Component({
  selector: 'app-household-head-farmland',
  templateUrl: './household-head-farmland.component.html',
  styleUrls: ['../../farmers.component.scss', './household-head-farmland.component.scss']
})

export class HouseholdHeadFarmlandComponent implements OnInit {
  currentLanguage: string = 'km';
  displayedColumns: string[] = [
    "code",
    "type",
    "purpose",
    "size",
    "width",   //ទទឹង
    "length",
    "irrigation_length",
  ];
  httpClient: HttpClient;
  mapKey: string = environment.google_maps_key;
  apiLoaded: Observable<boolean>;
  options: google.maps.MapOptions = {
    zoom: 7,
    zoomControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    center: {
      lat: 12.5988097,    // lat & lng of the Cambodia
      lng: 105.371569
    }
  };
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: FarmlandLatLngLiteral[] = [];
  styleDisplay: string = 'none';
  selectedFarmland = { label: '', created_at: '' };

  @Input() farmlands: Array<Farmland>;

  constructor(
    private translate: TranslateService,
    httpClient: HttpClient,
  ) {
    this.httpClient = httpClient;
  }

  ngOnInit(): void {
    this.currentLanguage = this.translate.currentLang;
    this.initMapMarkers();
  }

  initMap(): void {
    this.apiLoaded = this.httpClient
      .jsonp(
        'https://maps.googleapis.com/maps/api/js?key=' + this.mapKey,
        'callback'
      )
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  initMapMarkers() {
    if (this.farmlands.length > 0) {
      this.farmlands.map(farmland => {
        if(farmland.location.coordinates.length == 2) {
          this.markerPositions.push({
            lat: farmland.location.coordinates[1],
            lng: farmland.location.coordinates[0],
            label: farmland.code,
            created_at: farmland.created_at,
          });
        }
      });
    }
    this.initMap();
  }

  dialogDisplay(location: FarmlandLatLngLiteral): void {
    this.styleDisplay = 'block';
    this.selectedFarmland = {
      label: location.label,
      created_at: location.created_at
    };
  }

  hideDialog(): void {
    this.styleDisplay = 'none';
  }
}