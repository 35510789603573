<div class="page-layout">
  <div class="page-header">
    <mat-toolbar class="custom-toolbar" role="heading">
      <div>
        <h2 class="mat-h2">
          <ng-content select="[page-title]"></ng-content>
        </h2>
        <app-breadcrumb></app-breadcrumb>
      </div>
      <div class="header-action">
        <ng-content select="[header-action]"></ng-content>
      </div>
    </mat-toolbar>
  </div>
  <div class="page-content">
    <ng-content select="[page-content]"></ng-content>
  </div>
  <div class="page-footer">
    <ng-content select="[page-footer]"></ng-content>
  </div>
</div>
