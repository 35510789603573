import { LoginData } from './../models/datas/login.data';
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { map } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageEnum } from '../models/enums/local-storage.enum';
import { NavigationStart, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FullNamePipe } from '../shares/name/pipes/full-name.pipe';
import { UserWeatherService } from './user-weather.service';
import { Profile } from '../models/profile';
import { RoleEnum } from '../models/enums/role.enum';
import _ from 'lodash';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authChange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    this.isAuth
  );
  authStatus: boolean = this.isAuth;
  weatherProfile: Profile;
  constructor(
    private router: Router,
    private requestService: RequestService,
    private localStorageService: LocalStorageService,
    private weatherService: UserWeatherService,
    private snackBarService: SnackbarService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.authStatus != this.isAuth) {
          this.markStatusChange();
        }
      }
    });
  }

  login(data: { identifier: string; password: string }): Observable<LoginData> {
    const data1 = {
      identifier: data.identifier,
      password: data.password,
    };
    return this.requestService
      .postJSON<LoginData>(environment.auth_url + '/authenticate', {
        data: data1,
      })
      .pipe(
        map(
          (res) => {
            this.onLoginSuccess(res);
            return res;
          },
          (err) => {
            console.error(err);
          }
        )
      );
  }

  fullNamePipe = new FullNamePipe();

  onLoginSuccess(res) {
    const token = res.token;
    const userId = res.user._id;
    const fullName = this.fullNamePipe.transform(res.user, '');
    const photoUrl = res.user.photo_url;

    const roles = res?.user?.roles;
    this.localStorageService.set(LocalStorageEnum.token, token);
    this.localStorageService.set(LocalStorageEnum.refresh_token, res.refresh_token);
    this.localStorageService.set(LocalStorageEnum.user_id, userId);
    this.localStorageService.set(LocalStorageEnum.user_fullname, fullName);
    this.localStorageService.set(LocalStorageEnum.user_photo_url, photoUrl);
    this.localStorageService.set(LocalStorageEnum.user_roles, JSON.stringify(roles));
    this.getWeatherProfile();
  }

  getWeatherProfile(): void {
    this.weatherService.getWeatherProfile().subscribe(
      (res) => {
        this.localStorageService.set(
          LocalStorageEnum.province_id,
          res.data?.province?._id ?? 0
        );
        this.localStorageService.set(
          LocalStorageEnum.district_id,
          res.data?.district?._id ?? 0
        );
        this.localStorageService.set(
          LocalStorageEnum.commune_id,
          res.data?.province?._id ?? 0
        );
        this.localStorageService.set(
          LocalStorageEnum.village_id,
          res.data?.village?._id ?? 0
        );
        this.localStorageService.set(
          LocalStorageEnum.operator_id,
          res.data?.operator_id
        );

        this.navigateByRole().then(() => {
          window.location.reload();
        });

        this.authChange$.next(true);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  logout() {
    this.localStorageService.delete(LocalStorageEnum.token);
    this.localStorageService.delete(LocalStorageEnum.refresh_token);
    this.localStorageService.delete(LocalStorageEnum.user_id);
    this.localStorageService.delete(LocalStorageEnum.user_fullname);
    this.localStorageService.delete(LocalStorageEnum.user_photo_url);
    this.localStorageService.delete(LocalStorageEnum.user_roles);
    this.localStorageService.delete(LocalStorageEnum.province_id);
    this.localStorageService.delete(LocalStorageEnum.district_id);
    this.localStorageService.delete(LocalStorageEnum.commune_id);
    this.localStorageService.delete(LocalStorageEnum.village_id);
    this.logoutToken();
    this.markStatusChange();
  }

  logoutToken() {
    return this.requestService.deleteJSON(environment.auth_url + '/logout-token');
  }

  private markStatusChange() {
    this.authChange$.next(this.isAuth);
    this.authStatus = this.isAuth;
  }

  get isAuth(): boolean {
    return this.localStorageService.get(LocalStorageEnum.token) ? true : false;
  }

  /**
   * This function is using in selection in create and edit users
   * User can create users which below their roles
   * For example, provincial role can't create another province users,
   * Can create users only belong to their province, so entrepreneur_manager & entrepreneur roles
   * @returns string[]
   */
  getRoleBelongs(): string[] {
    if (this.isNationalRole()) {
      return [
        RoleEnum.NATIONAL_MANAGER,
        RoleEnum.PROVINCIAL_MANAGER,
        RoleEnum.ENTREPRENEUR_MANAGER,
        RoleEnum.ENTREPRENEUR,
        RoleEnum.DISASTER_MANAGER
      ];
    } else if (this.isProvincialRole()) {
      return [
        RoleEnum.ENTREPRENEUR_MANAGER,
        RoleEnum.ENTREPRENEUR
      ];
    } else if (this.isDistrictRole()) {
      return [
        RoleEnum.ENTREPRENEUR
      ];
    } else if (this.isOrganizationManagerRole()) {
      return [
        RoleEnum.ORGANIZATION_MANAGER,
        RoleEnum.ORGANIZATION_STAFF
      ];
    }

    return [];
  }

  getRoles(): string[] {
    return JSON.parse(this.localStorageService.get(LocalStorageEnum.user_roles));
  }

  getCurrentUser() {
    return {
      _id: this.localStorageService.get(LocalStorageEnum.user_id),
      full_name: this.localStorageService.get(LocalStorageEnum.user_fullname),
      photo_url: this.localStorageService.get(LocalStorageEnum.user_photo_url),
      province_id: Number(this.localStorageService.get(LocalStorageEnum.province_id)) ?? 0,
      district_id: Number(this.localStorageService.get(LocalStorageEnum.district_id)) ?? 0,
      commune_id: Number(this.localStorageService.get(LocalStorageEnum.commune_id)) ?? 0,
      village_id: Number(this.localStorageService.get(LocalStorageEnum.village_id)) ?? 0,
      role: this.getRole(),
      operator_id: this.localStorageService.get(LocalStorageEnum.operator_id) ?? '',
    }
  }

  getRole(): string {
    if (this.isNationalRole()) return RoleEnum.NATIONAL_MANAGER;
    if (this.isProvincialRole()) return RoleEnum.PROVINCIAL_MANAGER;
    if (this.isDistrictRole()) return RoleEnum.ENTREPRENEUR_MANAGER;
    if (this.isEntrepreneurRole()) return RoleEnum.ENTREPRENEUR;
    if (this.isOrganizationManagerRole()) return RoleEnum.ORGANIZATION_MANAGER;
    if (this.isOrganizationStaffRole()) return RoleEnum.ORGANIZATION_STAFF;
    if (this.isDisasterManagerRole()) return RoleEnum.DISASTER_MANAGER;
  }

  /** These functions are useful for filtering by roles (Address filtering)
   *  there are provinces selection, district selection, commune selection, and village selection
   *
   **/
  hasAccess = (roles: string[]): boolean =>
    _.intersection(this.getRoles(), roles).length > 0;
  isNationalRole = (): boolean => this.hasAccess([RoleEnum.NATIONAL_MANAGER]);
  isProvincialRole = (): boolean => this.hasAccess([RoleEnum.PROVINCIAL_MANAGER]);
  isDistrictRole = (): boolean => this.hasAccess([RoleEnum.ENTREPRENEUR_MANAGER]);
  isEntrepreneurRole = (): boolean => this.hasAccess([RoleEnum.ENTREPRENEUR]);
  isOrganizationManagerRole = (): boolean => this.hasAccess([RoleEnum.ORGANIZATION_MANAGER]);
  isOrganizationStaffRole = (): boolean => this.hasAccess([RoleEnum.ORGANIZATION_STAFF]);
  isDisasterManagerRole = (): boolean => this.hasAccess([RoleEnum.DISASTER_MANAGER]);

  /** end filtering by role functions */

  /**
   * This function for navigation based on user role
   */
  navigateByRole(): Promise<boolean> {
    if (
      this.isNationalRole() ||
      this.isProvincialRole() ||
      this.isDistrictRole() ||
      this.isOrganizationManagerRole()
    ) return this.router.navigate(['dashboard']);

    if (this.isEntrepreneurRole() || this.isOrganizationStaffRole()) return this.router.navigate(['report']);
    if (this.isDisasterManagerRole()) return this.router.navigate(['disaster-warning/announcement']);

    this.snackBarService.onShowSnackbar("Status.UnknownRole", false);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        return this.router.navigate(['login']);
      }, 300);
    });
  }
}
