import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { Profile } from 'src/app/models/profile';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ProfileService } from 'src/app/services/profile.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

  form: FormGroup;
  isLoading: boolean = false;
  profile: Profile;
  token: String = '';
  imgUrl: any;

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService,
    private snackBarService: SnackbarService,
    private localStorageService: LocalStorageService,
    private dialogRef: MatDialogRef<EditProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Profile,
  ) { }

  ngOnInit(): void {
    this.token = this.localStorageService.get(LocalStorageEnum.token);
    this.profile = this.data;
    this.initForm();
    if (!this.data) return;
    this.patchValue(this.data);
  }

  initForm() {
    this.form = this.fb.group({
      file: [null],
      last_name: [null, Validators.required],
      first_name: [null, Validators.required]
    });
  }

  patchValue(profile: Profile) {
    this.form.patchValue({
      first_name: profile?.user.first_name,
      last_name: profile?.user.last_name
    })
  }

  onSubmit() {
    if (this.form.valid) {
      this.isLoading = true;
      const formValue = this.form.value;
      const updateData = {
        last_name: formValue.last_name,
        first_name: formValue.first_name
      }

      this.profileService.updateProfile(updateData).subscribe(
        (res) => {
          this.isLoading = false;
          if (res) {
            this.snackBarService.onShowSnackbar('Status.UpdateSuccess');
            this.dialogRef.close(formValue);
          } else {
            this.snackBarService.onShowSnackbar('Status.Failed', false);
          }
        },
        (err) => {
          console.error(err);
          this.isLoading = false;
          this.snackBarService.onShowSnackbar('Status.SomethingWrong', false);
        }
      );
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSelectFile(event) {
    //== upload image
    var fileData = event.target.files[0];
    this.uploadProfile(fileData);

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      //== display current image
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        this.imgUrl = event.target.result;
      }
    }
  }

  uploadProfile(fileData): void {
    const data = {
      file: fileData
    }

    this.profileService.uploadProfilePhoto(data).subscribe(
      (res) => {
      },
      (err) => {
        console.error(err);
        this.snackBarService.onShowSnackbar('Status.UpdateFailed', false);
      }
    );
  }
}
