import { Pipe, PipeTransform } from '@angular/core';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Pipe({
  name: 'token'
})
export class TokenPipe implements PipeTransform {
  token: string = '';
  constructor(private localStorageService: LocalStorageService) {
    this.token = this.localStorageService.get(LocalStorageEnum.token);
  }

  transform(url: string, defaultUrl: string = ''): string {
    defaultUrl = defaultUrl == '' ? 'assets/svgs/avatar-person.svg' : defaultUrl;
    if (!url || url == '') return defaultUrl;

    return `${url}?token=${this.token}`;
  }

}
