import { BaseCrudService } from './base-crud.service';
import { Injectable, Injector } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { Profile } from '../models/profile';
import { RequestParam } from '../models/request-param';

@Injectable({
    providedIn: 'root'
})
export class UserService extends BaseCrudService<null> {

    constructor(injector: Injector) {
        super(injector);
        this.path = environment.auth_url;
    }

    searchUser(data: {}): Observable<User[]>{
        return this.requestService.getJSON<User[]>(
            this.path + '/users/search', { data }
        );
    }

    updateInformation(data: User){
        return this.requestService.putJSON(
            this.path + '/me', { data }
        );
    }

    changePassword(data: {}){
        return this.requestService.postJSON(
            this.path + '/me/change-password', { data }
        );
    }

    getProfile(data?: RequestParam) {
        return this.requestService.getJSON<Profile>(
            this.path + '/authenticate-jwt', data
        );
    }
}
