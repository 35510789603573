import { FormGroup, ValidatorFn } from '@angular/forms';

export function MustMatch(password: string, confirm_password: string) {
    return (formGroup: FormGroup): ValidatorFn | ValidatorFn[] => {
        const newControl = formGroup.controls[password];
        const confirmControl = formGroup.controls[confirm_password];

        if (confirmControl.errors && !confirmControl.errors.mustMatch) {
            return;
        }

        if (newControl.value !== confirmControl.value) {
            confirmControl.setErrors({ mustMatch: true });
        } else {
            confirmControl.setErrors(null);
        }
    }
}
