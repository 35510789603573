import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainComponent } from './components/main/main.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptor } from './intercptors/auth.interceptor';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { SnackbarComponent } from './shares/snackbar/snackbar.component';
import { NameModule } from './shares/name/name.module';

import * as moment from 'moment';
import { MomentModule } from 'ngx-moment';
import { LayoutModule } from '@angular/cdk/layout';
import { SidebarComponent } from './components/main/sidebar/sidebar.component';
import { ConfirmDialogComponent } from './shares/confirm-dialog/confirm-dialog.component';
import { ProfileImageModule } from './shares/profile-image/profile-image.module';
import { HeaderComponent } from './components/main/header/header.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { PageLayoutModule } from './shares/page-layout/page-layout.module';
import { AnnualYieldComponent } from './routes/annual-yield/annual-yield.component';
import { AnnualYieldRoutingModule } from './routes/annual-yield/annual-yield-routing.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { PaginationModule } from './shares/pagination/pagination.module';
import { MatInputModule } from '@angular/material/input';
import { ProvincialYieldComponent } from './routes/provincial-yield/provincial-yield.component';
import { ProvincialYieldRoutingModule } from './routes/provincial-yield/provincial-yield-routing.module';
import { FarmersRoutingModule } from './routes/farmers/farmers-routing.module';

moment.updateLocale('km', {
  months: [
    'មករា',
    'កុម្ភៈ',
    'មីនា',
    'មេសា',
    'ឧសភា',
    'មិថុនា',
    'កក្កដា',
    'សីហា',
    'កញ្ញា',
    'តុលា',
    'វិច្ឆិកា',
    'ធ្នូ',
  ],
  monthsShort: [
    'មករា',
    'កុម្ភៈ',
    'មីនា',
    'មេសា',
    'ឧសភា',
    'មិថុនា',
    'កក្កដា',
    'សីហា',
    'កញ្ញា',
    'តុលា',
    'វិច្ឆិកា',
    'ធ្នូ',
  ],
  monthsParseExact: true,
  weekdays: [
    'ថ្ងៃអាទិត្យ',
    'ថ្ងៃច័ន្ទ',
    'ថ្ងៃអង្គារ',
    'ថ្ងៃពុធ',
    'ថ្ងៃព្រហស្បតិ៍',
    'ថ្ងៃសុក្រ',
    'ថ្ងៃសៅរ៌',
  ],
  weekdaysShort: [
    'អាទិត្យ',
    'ច័ន្ទ',
    'អង្គារ',
    'ពុធ',
    'ព្រហស្បតិ៍',
    'សុក្រ',
    'សៅរ៌',
  ],
  weekdaysMin: ['អា', 'ច', 'អ', 'ព', 'ព្រ', 'សុ', 'ស'],
  weekdaysParseExact: true,
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY hh:mm A',
    LLLL: 'dddd D MMMM YYYY hh:mm A',
  },
  calendar: {
    sameDay: '[ថ្ងៃនេះ]',
    nextDay: '[ថ្ងៃស្អែក]',
    nextWeek: '[អាទិត្យក្រោយ]',
    lastDay: '[ម្សិលមិញ]',
    lastWeek: '[អាទិត្យមុន]',
    sameElse: 'DD/MM/YYYY',
  },
  relativeTime: {
    future: '%sខាងមុខ',
    past: '%sមុន',
    s: 'បន្តិច',
    m: 'មួយនាទី',
    mm: '%d នាទី',
    h: 'មួយម៉ោង',
    hh: '%d ម៉ោង',
    d: 'មួយថ្ងៃ',
    dd: '%d ថ្ងៃ',
    M: 'មួយខែ',
    MM: '%d ខែ',
    y: 'មួយឆ្នាំ',
    yy: '%d ឆ្នាំ',
  },
  dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
  ordinal: function (number) {
    return number + (number === 1 ? 'er' : 'e');
  },
  meridiemParse: /AM|PM/,
  isPM: function (input) {
    return input.charAt(0) === 'M';
  },
  // In case the meridiem units are not separated around 12, then implement
  // this function (look at locale/id.js for an example).
  // meridiemHour : function (hour, meridiem) {
  //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
  // },
  meridiem: function (hours, minutes, isLower) {
    return hours < 12 ? 'ព្រឹក' : 'ល្ងាច';
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // Used to determine first week of the year.
  },
});

export function translateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    NotFoundComponent,
    SnackbarComponent,
    SidebarComponent,
    ConfirmDialogComponent,
    HeaderComponent,
    AnnualYieldComponent,
    ProvincialYieldComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    MatButtonModule,
    MatListModule,
    MatSidenavModule,
    MatIconModule,
    MatToolbarModule,
    MatTooltipModule,
    MatMenuModule,
    RouterModule,
    MatDialogModule,
    ReactiveFormsModule,
    NameModule,
    MatSelectModule,
    ProfileImageModule,
    MatFormFieldModule,
    MatChipsModule,

    ProvincialYieldRoutingModule,
    AnnualYieldRoutingModule,
    FarmersRoutingModule,
    FormsModule,
    PageLayoutModule,
    MatDatepickerModule,
    MatTableModule,
    MatNativeDateModule,
    PaginationModule,
    MatInputModule,


    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        s: 59,
        m: 59,
        h: 23,
      },
    }),
    LayoutModule,
    MatTreeModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    /**
     * Is used for refresh token
     */
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  //Define available languages
  availableLang = ['en', 'km'];

  constructor(private translateService: TranslateService) {
    let tmpLang = 'km';
    this.translateService.setDefaultLang(tmpLang);
    this.translateService.use(tmpLang);
    moment.locale(tmpLang);
  }
}
