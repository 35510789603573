import { Component, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  @ViewChild('sidenav') sidenav: MatDrawer;
  showNav: boolean = true;

  constructor(private breakpointObserver: BreakpointObserver) {
    breakpointObserver
      .observe([
        Breakpoints.HandsetLandscape,
        Breakpoints.HandsetPortrait,
        Breakpoints.TabletLandscape,
        Breakpoints.Small,
      ])
      .subscribe((result) => {
        if (result.matches) {
          this.showNav = false;
        } else {
          this.showNav = true;
        }
      });
  }

  toggleShowNav(): void {
    this.showNav = !this.showNav;
  }
}
