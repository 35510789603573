<app-page-layout>
  <ng-container page-title>
    {{ "FarmerDetail.Title" | translate }}
  </ng-container>

  <ng-container page-content>
    <mat-tab-group class="mat-tab-group farmer-detail">
      <mat-tab label="{{ 'FarmerPage.FarmerInfo' | translate }}">
        <app-household-head-tab [farmer]="farmer" [farmerId]="farmerId">
        </app-household-head-tab>
      </mat-tab>
      <mat-tab label="{{ 'FarmerMemberInfo.Title' | translate }}">
        <app-family-member-tab [farmer]="farmer">
        </app-family-member-tab>
      </mat-tab>
      <mat-tab label="{{ 'FarmerDetail.Plan' | translate }}" *ngIf="farmer?.source && farmer?.source != farmerSourceEnum.KAS">
        <app-project-tab [farmer]="farmer">
        </app-project-tab>
      </mat-tab>
    </mat-tab-group>
  </ng-container>

  <div page-content *ngIf="isLoading" class="app-loading"></div>
</app-page-layout>