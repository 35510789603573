import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'
import { FarmersComponent } from './farmers.component'
import { FarmersDetailComponent } from './detail/farmers-detail.component';

const routes: Routes = [
  {
    path: '',
    component: FarmersComponent
  },
  {
    path: ':id',
    component: FarmersDetailComponent,
    data: {
      breadcrumbs: [
        {
          label: 'Dashboard.FarmerInfo',
          url: '/farmers',
        },
        {
          label: 'Breadcrumbs.View',
          url: '',
        },
      ],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FarmersRoutingModule { }