import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseResponse } from 'src/app/models/responses/base.response';
import { RequestService } from 'src/app/services/request.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FarmersService {
  protected requestService: RequestService;

  constructor(injector: Injector) {
    this.requestService = injector.get(RequestService);
  }

  getFarmers(data: {}): Observable<BaseResponse> {
    return this.requestService.get(environment.resource_url + '/farmers', { data });
  }

  getFarmerFarmlands(farmerId): Observable<BaseResponse> {
    return this.requestService.get(environment.kumrong_url + '/farmers/' + farmerId + '/farmlands');
  }
}