<div class="breadcrumb-wrapper">
  <nav role="navigation">
    <ul *ngIf="breadcrumbs?.length > 0">
      <li class="bc-l-item" *ngFor="let breadcrumb of breadcrumbs; let i = index">
        <span class="bc-link" *ngIf="i !== breadcrumbs.length - 1" [routerLink]="breadcrumb.url" routerLinkActive="router-link-active">
          {{ breadcrumb.label | translate }}
        </span>
        <span *ngIf="i !== breadcrumbs.length - 1" class="separator">»</span>
        <span *ngIf="i === breadcrumbs.length - 1">
          {{ breadcrumb.label | translate }}
        </span>
      </li>
    </ul>
  </nav>
</div>
