import { RoleEnum } from 'src/app/models/enums/role.enum';

/**
 * Sidenav data with nested structure.
 * Each node has a name, icon and an optional list of children.
 */
export interface SidenavNode {
  name: string;
  link: string | null; // router link
  icon: {
    name: string;
    class: string;
  };
  children?: SidenavNode[];
  roles?: string[];
}

export const SidenavMenu: SidenavNode[] = [
  {
    name: 'Sidenav.Dashboard',
    link: 'dashboard',
    icon: {
      name: 'icon-home',
      class: 'svg-path svg-fill',
    },
    roles: [
      RoleEnum.NATIONAL_MANAGER,
      RoleEnum.ORGANIZATION_MANAGER,
      RoleEnum.PROVINCIAL_MANAGER,
      RoleEnum.ENTREPRENEUR_MANAGER
    ],
  },
  {
    name: 'Sidenav.Soil.Sub1',
    link: 'soil-entry',
    icon: { name: 'icon-add-entry', class: 'svg-path svg-fill' },
    roles: [RoleEnum.ENTREPRENEUR_MANAGER],
  },
  {
    name: 'Sidenav.Report.Main',
    link: null,
    icon: { name: 'icon-report', class: 'svg-path svg-fill' },
    roles: [
      RoleEnum.NATIONAL_MANAGER,
      RoleEnum.ORGANIZATION_MANAGER,
      RoleEnum.PROVINCIAL_MANAGER,
      RoleEnum.ENTREPRENEUR_MANAGER,
      RoleEnum.ENTREPRENEUR,
      RoleEnum.ORGANIZATION_STAFF
    ],
    children: [
      {
        name: 'Sidenav.Report.Sub1',
        link: 'report',
        icon: { name: 'icon-data-collection', class: 'svg-path svg-fill' },
        roles: [
          RoleEnum.NATIONAL_MANAGER,
          RoleEnum.ORGANIZATION_MANAGER,
          RoleEnum.PROVINCIAL_MANAGER,
          RoleEnum.ENTREPRENEUR_MANAGER,
          RoleEnum.ENTREPRENEUR,
          RoleEnum.ORGANIZATION_STAFF
        ],
      },
      {
        name: 'Sidenav.Report.Sub2',
        link: 'map',
        icon: { name: 'icon-map', class: 'svg-path svg-fill' },
        roles: [
          RoleEnum.NATIONAL_MANAGER,
          RoleEnum.ORGANIZATION_MANAGER,
          RoleEnum.PROVINCIAL_MANAGER,
          RoleEnum.ENTREPRENEUR_MANAGER,
        ],
      },
      {
        name: 'Sidenav.Report.Sub3',
        link: 'annual-yield',
        icon: { name: 'icon-calendar-2', class: 'svg-path svg-fill' },
        roles: [
          RoleEnum.NATIONAL_MANAGER,
          RoleEnum.PROVINCIAL_MANAGER,
          RoleEnum.ORGANIZATION_MANAGER,
          RoleEnum.ORGANIZATION_STAFF,
        ],
      },
      {
        name: 'Sidenav.Report.Sub4',
        link: 'provincial-yield',
        icon: { name: 'icon-locate', class: 'svg-path svg-fill' },
        roles: [
          RoleEnum.NATIONAL_MANAGER,
          RoleEnum.PROVINCIAL_MANAGER,
          RoleEnum.ORGANIZATION_MANAGER,
          RoleEnum.ORGANIZATION_STAFF,
        ],
      },
      {
        name: 'Dashboard.FarmerInfo',
        link: 'farmers',
        icon: { name: 'icon-list', class: 'svg-path svg-fill' },
        roles: [
          RoleEnum.NATIONAL_MANAGER,
          RoleEnum.PROVINCIAL_MANAGER,
          RoleEnum.ORGANIZATION_MANAGER,
          RoleEnum.ORGANIZATION_STAFF,
          RoleEnum.ENTREPRENEUR_MANAGER,
          RoleEnum.ENTREPRENEUR,
        ],
      },
    ],
  },
  {
    name: 'Sidenav.UserData',
    link: 'users',
    icon: { name: 'icon-list', class: 'svg-path svg-fill' },
    roles: [
      RoleEnum.NATIONAL_MANAGER,
      RoleEnum.ORGANIZATION_MANAGER,
      RoleEnum.PROVINCIAL_MANAGER,
      RoleEnum.ENTREPRENEUR_MANAGER,
    ],
  },
  {
    name: 'Sidenav.Market',
    link: 'market',
    icon: { name: 'icon-market', class: 'svg-path svg-fill' },
    roles: [
      RoleEnum.ENTREPRENEUR,
      RoleEnum.ORGANIZATION_STAFF
    ],
  },
  {
    name: 'Sidenav.Organization',
    link: 'organization',
    icon: { name: 'icon-organization', class: 'svg-path svg-fill' },
    roles: [
      RoleEnum.NATIONAL_MANAGER
    ],
  },
  {
    name: 'Sidenav.Setting.Main',
    link: null,
    icon: { name: 'icon-setting', class: 'svg-path svg-fill svg-stroke' },
    roles: [
      RoleEnum.NATIONAL_MANAGER,
    ],
    children: [
      {
        name: 'Sidenav.Setting.Sub1',
        link: 'crop-classification',
        icon: { name: 'icon-crop-classification', class: 'svg-path svg-fill' },
        roles: [
          RoleEnum.NATIONAL_MANAGER,
        ]
      },
      {
        name: 'Sidenav.Setting.Sub2',
        link: 'crop-cycle-templates/list',
        icon: { name: 'icon-create-template', class: 'svg-path svg-fill' },
        roles: [
          RoleEnum.NATIONAL_MANAGER,
        ],
      },
      {
        name: 'Sidenav.Setting.Sub3',
        link: 'crop',
        icon: { name: 'icon-crop', class: 'svg-path svg-fill' },
        roles: [
          RoleEnum.NATIONAL_MANAGER,
        ],
      },
      {
        name: 'Sidenav.Setting.Sub4',
        link: 'crop-cycle-templates/stages',
        icon: { name: 'icon-plus-bag', class: 'svg-path svg-fill' },
        roles: [
          RoleEnum.NATIONAL_MANAGER,
        ],
      },

      {
        name: 'Sidenav.Setting.Sub5',
        link: 'nutrient',
        icon: { name: 'icon-nutrient', class: 'svg-path svg-fill' },
        roles: [
          RoleEnum.NATIONAL_MANAGER,
        ],
      },
      {
        name: 'Sidenav.Setting.Sub6',
        link: 'manage-weather-parameter',
        icon: { name: 'disaster1', class: 'svg-path svg-stroke' },
        roles: [
          RoleEnum.NATIONAL_MANAGER,
        ],
      },
    ],
  },
  {
    name: 'DISASTER_WARNING.MANAGE_DISASTER',
    link: null,
    icon: { name: 'weather-app', class: 'svg-path svg-fill svg-stroke' },
    roles: [
      RoleEnum.NATIONAL_MANAGER,
      RoleEnum.DISASTER_MANAGER
    ],
    children: [
      {
        name: 'DISASTER_WARNING.LIST.METEOROLOGY',
        link: 'disaster-warning/disaster-list',
        icon: { name: 'season', class: 'svg-path svg-fill' },
        roles: [
          RoleEnum.NATIONAL_MANAGER,
        ],
      },
      {
        name: 'DISASTER_WARNING.LIST.ANNOUNCEMENT',
        link: 'disaster-warning/announcement',
        icon: { name: 'artboard501', class: 'svg-path svg-fill' },
        roles: [
          RoleEnum.NATIONAL_MANAGER,
          RoleEnum.DISASTER_MANAGER,
        ],
      }
    ],
  }
];
