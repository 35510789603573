import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { formatCurrency } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { KumrongService } from 'src/app/services/kumrong.service';
import { Farmland } from 'src/app/models/farmland';

const ALL = 0;
const IN_PROGRESS = 1;
const SUCCESS = 2;
const UNSUCCESS = 3;

@Component({
  selector: 'app-household-head-crop-cycle',
  templateUrl: './household-head-crop-cycle.component.html',
  styleUrls: ['../../farmers.component.scss']
})

export class HouseholdHeadCropCycleComponent implements OnInit {
  allCropCycles: Array<any> = [];
  cropCycles: Array<any> = [];
  currentLanguage: string = 'km';
  displayedColumns: string[] = [
    "farmland_code",
    "crop",
    "estimate_harvest_date",
    "estimate_harvest_qty",
    "estimate_revenue",
    "start_date",
    "end_date",
    "gross_profit",
    "close_reason"
  ];

  statuses = [
    {label: 'All', value: ALL},
    {label: 'InProgress', value: IN_PROGRESS},
    {label: 'Successful', value: SUCCESS},
    {label: 'Unsuccessful', value: UNSUCCESS}
  ];

  selectedStatus = 0;

  @Input() farmerId: string;
  @Input() farmlands: Array<Farmland>;

  constructor(
    private translate: TranslateService,
    private service: KumrongService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.currentLanguage = this.translate.currentLang;
    this.getCropCycles();
  }

  getCropCycles() {
    this.farmlands.map(farmland => {
      this.service.getCropCycleByFarmerFarmland(this.farmerId, farmland._id, {}).subscribe((res) => {
        let result = res.data;
        result.map((cropCycle, index) => {
          cropCycle.hidden = index == 0 ? false : true;
          cropCycle.rowSpan = res.data.length;
          cropCycle.estimate_revenue = !!cropCycle.estimate_revenue ? formatCurrency(cropCycle.estimate_revenue, 'en', '') : cropCycle.estimate_revenue;
          cropCycle.gross_profit = !!cropCycle.gross_profit ? formatCurrency(cropCycle.gross_profit, 'en', '') : cropCycle.gross_profit;
        })
        this.cropCycles = [...this.cropCycles, ...result];
        this.allCropCycles = this.cropCycles;
      }, (err) => {
        console.log(err);
      });
    });
  }

  getStatusColor(closeReason) {
    if (!closeReason)
      return "#20d220";

    return closeReason == "SUCCESS" ? "#55aecb" : "#c90000";
  }

  onSelectChange(event: any) {
    let filteredList = [];
    if (event?.value == ALL)
      filteredList = this.allCropCycles;
    else {
      filteredList = this.allCropCycles.filter(cropCycle => {
        if (event?.value == IN_PROGRESS)
          return cropCycle.close_reason == undefined;

        return event?.value == SUCCESS ? cropCycle.close_reason == "SUCCESS" : (!!cropCycle.close_reason && cropCycle.close_reason != "SUCCESS")
      });
    }

    const groupedData = filteredList.reduce((grouped, item) => {
      const farmlandCode = item.farmland.code;
      if (!grouped[farmlandCode]) {
        grouped[farmlandCode] = [];
      }
      grouped[farmlandCode].push(item);
      return grouped;
    }, {});

    this.cropCycles = [];
    Object.entries(groupedData).forEach(([key, value]) => {
      let items = value as any[];
      items.forEach((item, index) => {
        item.hidden = index == 0 ? false : true;
        item.rowSpan = items.length;
      });
      this.cropCycles = [...this.cropCycles, ...items];
    });
  }

  hasChildren = (node: any) => !!node.children;
}