export enum RoleEnum {
  ENTREPRENEUR = 'entrepreneur',
  ENTREPRENEUR_MANAGER = 'entrepreneur_manager',
  PROVINCIAL_MANAGER = 'provincial_manager',
  NATIONAL_MANAGER = 'national_manager',
  PORTAL = 'portal',
  ORGANIZATION_MANAGER = 'organization_manager',
  ORGANIZATION_STAFF = 'organization_staff',
  DISASTER_MANAGER = 'disaster_manager',
}
