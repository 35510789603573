import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Event } from '@angular/router';
import { filter, distinctUntilChanged } from 'rxjs/operators';
import { IBreadCrumb } from 'src/app/models/breadcrumb';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: IBreadCrumb[]
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.buildBreadCrumb(this.activatedRoute.root);
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd),
      distinctUntilChanged(),
    ).subscribe((r) => {
      this.buildBreadCrumb(this.activatedRoute.root);
    })
  }

  /**
   * Recursively build breadcrumb according to activated route.
   * @param route
   */
  buildBreadCrumb(route: ActivatedRoute) {
    if (route.routeConfig && route.routeConfig.data) {
      let params = route.snapshot.params;
      this.breadcrumbs = route.routeConfig.data.breadcrumbs;
      if (params.id) {
        this.breadcrumbs.map((bc) => {
          bc.url = bc.url.replace(':id', params?.id);
          return bc;
        });
      }
      if (params.id2) {
        this.breadcrumbs.map((bc) => {
          bc.url = bc.url.replace(':id2', params?.id2);
          return bc;
        });
      }
    }

    if (route.firstChild) {
      //If we are not on our current path yet,
      //there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild);
    }
  }
}
