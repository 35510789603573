import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../shares/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  status: number;
  constructor(
    private snackBar: MatSnackBar
  ) { }

  onShowSnackbar(message: string, b: boolean = true) {
    this.status = b ? 1 : 0;
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: { message, status: this.status },
      panelClass: [(b ? "snackbar-success" : "snackbar-error"), "snackbar-custom"],
      duration: 5000
    });
  }

}
