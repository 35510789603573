<div class="v-pagin" *ngIf="total > 0">
  <button class="p-btn-first" [disabled]="page===1 || total===0" (click)="goTo(page - 1)">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <button *ngIf="start!==1  && total!==0" [disabled]="true">...</button>
  <button *ngFor="let i of pageList" [ngClass]="{'active':i===page}" (click)="goTo(i)">
    {{i}}
  </button>
  <button *ngIf="end!==last && total!==0" [disabled]="true">...</button>
  <button class="p-btn-last" [disabled]="page===last  || total===0" (click)="goTo(page + 1)">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
  <mat-select [(value)]="limit" (selectionChange)="goTo(1)">
    <mat-option *ngFor="let option of pageOptions" [value]="option"> {{option}}<span> / {{'Page' | translate}}</span> </mat-option>
  </mat-select>
</div>