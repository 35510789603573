<div class="mt-4">
  <div class="row mb-3">
    <div class="col row">
      <div class="col-auto">
        <h3 class="sub-title-content pt-2">
          {{ "Breadcrumbs.CropCycle" | translate }}
        </h3>
      </div>

      <div class="col-auto">
        <button mat-stroked-button class="btn-crop-cycle-status">
          <mat-select class="province-custom-select" (selectionChange)="onSelectChange($event)" [(value)]="selectedStatus">
            <mat-option *ngFor="let status of statuses" [value]="status?.value">
              {{ "FarmerDetail.FilterCropCycle." + status?.label | translate }}
            </mat-option>
          </mat-select>
        </button>
      </div>
    </div>

    <div class="col-auto align-content-center py-2">
      <div class="d-flex">
        <div class="d-flex">
          <span class="farmland-status-badge mx-1" [style.backgroundColor]="getStatusColor('SUCCESS')"></span>
          <span>{{ "FarmerDetail.FilterCropCycle.Successful" | translate }}</span>
        </div>
        <div class="d-flex mx-3">
          <span class="farmland-status-badge mx-1" [style.backgroundColor]="getStatusColor('UNSUCCESS')"></span>
          <span>{{ "FarmerDetail.FilterCropCycle.Unsuccessful" | translate }}</span>
        </div>
        <div class="d-flex">
          <span class="farmland-status-badge mx-1" [style.backgroundColor]="getStatusColor(null)"></span>
          <span>{{ "FarmerDetail.FilterCropCycle.InProgress" | translate }}</span>
        </div>
      </div>
    </div>
  </div>

  <div>
    <table class="farmer-table" mat-table [dataSource]="cropCycles">
      <ng-container matColumnDef="farmland_code">
        <th mat-header-cell width="80" *matHeaderCellDef class="header-center">
          {{ "FarmlandPage.LandCode" | translate }}
        </th>
        <td mat-cell *matCellDef="let element; let i = index"
          [attr.rowspan]="element.rowSpan"
          [style.display]="element.hidden ? 'none' : ''"
        >
          {{ element?.farmland.code ?? ("N/A" | translate)}}
        </td>
      </ng-container>

      <ng-container matColumnDef="crop">
        <th mat-header-cell width="120" *matHeaderCellDef class="header-center">
          {{ "FarmlandPage.CropType" | translate }}
        </th>
        <td mat-cell *matCellDef="let element;">
          <div class="row px-2">
            <div class="col-1 px-0">
              <div class="farmland-status-badge" [style.backgroundColor]="getStatusColor(element?.close_reason)"></div>
            </div>
            <div class="col px-1">
              {{
                element?.crop
                ? currentLanguage == 'en' ? element?.crop?.name_en : element?.crop?.name_km
                : ("N/A" | translate)
              }}
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="estimate_harvest_date">
        <th mat-header-cell width="130" *matHeaderCellDef class="header-center">
          {{ "CropCycle.EstimateHarvestDate" | translate }}
        </th>
        <td mat-cell *matCellDef="let element;">
          {{
            element?.estimate_harvest_date
            ? (element?.estimate_harvest_date | amDateFormat: "DD / MMM / YYYY")
            : ("N/A" | translate)
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="estimate_harvest_qty">
        <th mat-header-cell width="100" *matHeaderCellDef class="header-center">
          {{ "CropCycle.EstimateHarvestQty" | translate }}
        </th>
        <td mat-cell *matCellDef="let element;">
          {{
            element?.estimate_harvest_quantity
            ? (element?.estimate_harvest_quantity + " " + ("UnitType.Kg" | translate))
            : ("N/A" | translate)
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="estimate_revenue">
        <th mat-header-cell width="100" *matHeaderCellDef class="header-center">
          {{ "CropCycle.EstimateRevenue" | translate }}
        </th>
        <td mat-cell *matCellDef="let element;" align="right">
          {{
            element?.estimate_revenue
            ? element?.estimate_revenue + ' ៛'
            : ("N/A" | translate)
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="start_date">
        <th mat-header-cell width="130" *matHeaderCellDef class="header-center">
          {{ "CropCyclePage.StartDate" | translate }}
        </th>
        <td mat-cell *matCellDef="let element;">
          {{
            element?.start_date
            ? (element?.start_date | amDateFormat: "DD / MMM / YYYY")
            : ("N/A" | translate)
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="end_date">
        <th mat-header-cell width="130" *matHeaderCellDef class="header-center">
          {{ "CropCyclePage.EndDate" | translate }}
        </th>
        <td mat-cell *matCellDef="let element;">
          {{
            element?.end_date
            ? (element?.end_date | amDateFormat: "DD / MMM / YYYY")
            : ("N/A" | translate)
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="gross_profit">
        <th mat-header-cell width="100" *matHeaderCellDef class="header-center">
          {{ "CropCyclePage.GrossProfit" | translate }}
        </th>
        <td mat-cell *matCellDef="let element;" align="right">
          {{
            element?.gross_profit
            ? element?.gross_profit + ' ៛'
            : ("N/A" | translate)
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="close_reason">
        <th mat-header-cell width="120" *matHeaderCellDef class="header-center">
          {{ "FarmerDetail.CloseReason" | translate }}
        </th>
        <td mat-cell *matCellDef="let element;">
          {{
            element?.close_reason
            ? ("CloseReason." + element?.close_reason | translate)
            : ("N/A" | translate)
          }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>