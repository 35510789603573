<div class="dialog-header">
  <h3 class="mat-h3" mat-dialog-title>
    {{ "FilterDialog.Title" | translate }}
  </h3>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <form id="form-0" [formGroup]="form" autocomplete="off" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-6">
        <h4>{{ "UsersPage.EnterInfo" | translate }}</h4>
      </div>
      <div class="col-6 d-flex flex-row-reverse">
        <div class="btn-md mat-stroked-button mat-button-base mat-primary d-flex align-items-center" matRipple
          (click)="onResetForm()"
          style="font-size: 14px; overflow: hidden;"
        >
          <mat-icon>autorenew</mat-icon>&nbsp;
           {{ "ActionBtn.Resets" | translate }}
        </div>
      </div>
    </div>

    <div class="form-content">
      <div class="form-group">
        <div class="row">
          <div class="col-sm-6">
            <mat-label>{{ "UserInfo.LastName" | translate }}</mat-label>
            <mat-form-field class="form-control" appearance="outline">
              <input matInput formControlName="last_name" />
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-label>{{ "UserInfo.FirstName" | translate }}</mat-label>
            <mat-form-field class="form-control" appearance="outline">
              <input matInput formControlName="first_name" />
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-label>{{ "ReportPage.IDCard" | translate }}</mat-label>
            <mat-form-field class="form-control" appearance="outline">
              <input matInput formControlName="id_card" />
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-label class="caption-sm">{{ "StartDate" | translate }} - {{ "EndDate" | translate }}
            </mat-label>
            <div class="row">
              <div class="col-sm-10" style="margin-right: -10px;">
                <mat-form-field class="form-control" appearance="outline">
                  <mat-date-range-input [formGroup]="form" [rangePicker]="picker" [hidden]=true>
                    <input formControlName="start_date" matStartDate placeholder="{{ 'StartDate' | translate }}"
                      (click)="picker.open()" />
                    <input formControlName="end_date" matEndDate placeholder="{{ 'EndDate' | translate }}" 
                      (click)="picker.open()" />
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker">
                    <mat-icon matDatepickerToggleIcon class="svg-path svg-fill" svgIcon="icon-calendar"></mat-icon>
                  </mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </div>
              <div class="col-sm-2 mt-2 p-0 mr-10">
                <div class="btn-md mat-stroked-button mat-button-base mat-primary text-center" matRipple
                  (click)="resetDate()"
                  style="font-size: 14px; padding-top: 6px; overflow: hidden;"
                >
                  {{ "All" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <mat-label [class.disabled-label]="provinces.length <= 0 || disabledProvince">{{ "FarmerPage.Provice" | translate }}</mat-label>
            <mat-form-field class="form-control" appearance="outline">
              <mat-select [disabled]="provinces.length <= 0 || disabledProvince"
                (selectionChange)="getDistricts($event.value, true)" formControlName="province">
                <mat-option *ngFor="let pro of provinces" [value]="pro._id">
                  {{ pro.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-label [class.disabled-label]="districts.length <= 0 || disabledDistrict">{{ "FarmerPage.DistrictKh" | translate }}</mat-label>
            <mat-form-field class="form-control" appearance="outline">
              <mat-select [disabled]="districts.length <= 0 || disabledDistrict"
                (selectionChange)="getCommunes($event.value, true)" formControlName="district">
                <mat-option *ngFor="let district of districts" [value]="district._id">
                  {{ district.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-label [class.disabled-label]="communes.length <= 0">{{ "FarmerPage.CommuneSk" | translate }}</mat-label>
            <mat-form-field class="form-control" appearance="outline">
              <mat-select [disabled]="communes.length <= 0" (selectionChange)="getVillages($event.value)"
                formControlName="commune">
                <mat-option *ngFor="let commune of communes" [value]="commune._id">
                  {{ commune.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-label [class.disabled-label]="villages.length <= 0">{{ "FarmerPage.Village" | translate }}</mat-label>
            <mat-form-field class="form-control" appearance="outline">
              <mat-select [disabled]="villages.length <= 0" formControlName="village">
                <mat-option *ngFor="let village of villages" [value]="village._id">
                  {{ village.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button class="btn-md btn-cancel" (click)="onCancel()" mat-stroked-button color="primary" mat-dialog-close aria-label="Cancel">
    <mat-icon svgIcon="icon-concel"></mat-icon>
    {{ "ActionBtn.Cancel" | translate }}
  </button>
  <button form="form-0" class="btn-md" mat-flat-button color="primary" cdkFocusInitial aria-label="Search">
    <mat-icon class="svg-path svg-fill" svgIcon="icon-search"></mat-icon>
    {{ "ActionBtn.Search" | translate }}
  </button>
</div>
<div *ngIf="isLoading" class="app-loading"></div>