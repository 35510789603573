import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Pagination } from 'src/app/models/pagination';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  pageList!: number[];
  start!: number;
  end!: number;
  last!: number;
  pageOptions = [10, 50, 100, 500];
  private _total!: number;
  private _current_page!: number;
  private _per_page!: number;
  constructor() { }

  ngOnInit() {
    this.update();
  }

  @Input()
  set total(total: number) {
    this._total = total;
    if (this.total && this._current_page && this._per_page) {
      this.update();
    }
  }

  get total(): number {
    return this._total;
  }

  @Input()
  set page(page: number) {
    this._current_page = page;
    this.update();
  }

  get page(): number {
    return this._current_page;
  }

  @Input()
  set limit(limit: number) {
    this._per_page = limit;
    if (this.total && this._current_page && this._per_page) {
      this.update();
    }
  }

  get limit(): number {
    return this._per_page;
  }

  @Input() surroundButton!: number;

  @Output() changed = new EventEmitter<Pagination>();

  goTo(current_page: number) {
    this.changed.emit({
      current_page,
      per_page: this._per_page
    });
    this.update();
  }

  update() {
    if (this.total > 0) {
      this.last = Math.ceil(this._total / this._per_page);
      this.start =
        this._current_page - Math.ceil(this.surroundButton / 2) > 0 ? this._current_page - Math.ceil(this.surroundButton / 2) : 1;
      this.end =
        this._current_page + Math.ceil(this.surroundButton / 2) < this.last
          ? this._current_page + Math.ceil(this.surroundButton / 2)
          : this.last;
      if (this.end - this.start < this.surroundButton) {
        if (this.end < this.last) {
          this.end = this.start + this.surroundButton < this.last ? this.start + this.surroundButton : this.last;
        }

        if (this.start > 1) {
          this.start = this.end - this.surroundButton > 1 ? this.end - this.surroundButton : 1;
        }
      }
      this.pageList = [];
      for (var i = this.start; i <= this.end; i++) {
        this.pageList.push(i);
      }
    } else {
      this.pageList = [];
    }
  }
}
