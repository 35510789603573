// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_url: 'https://kas-core.myoptistech.com',
  host_url: 'https://kas-weather-bo.myoptistech.com',
  auth_url: '/auth',
  weather_url: '/weather',
  file_weather_url: 'https://kas-core.myoptistech.com/weather/photos/',
  resource_url: '/resources',
  google_maps_key: 'AIzaSyAFDE8H5e080hqsuELaIEn2Wq18iPXn9hc',
  kumrong_url: '/kumrong',
  portal_url: '/portal',
  env_name: 'development',
  sentry_dsn: "https://576a648084d34e269b5cfc34c64b6e9b@sentry.optistech.com/14"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
